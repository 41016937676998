import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CountryActions from '../../Redux/actions/country-actions';
import { getLogin } from "../../Redux/reducers/account-reducer";
import BusinessActions from "../../Redux/actions/business-actions";
import DocumentActions from '../../Redux/transition/document-upload/document-upload.reducer';
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import removeBG from "../../assets/images/removeBG.jpg"
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { useTranslation } from "react-i18next";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import Loading from '../../Components/Loading';
import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import validator from 'validator';
import { isValidPhoneNumber } from 'libphonenumber-js'
import "../../Styles/pages/settings.scss"
import "react-phone-number-input/style.css";

function BusinessEdit(props) {

    //Redux State from Store
    const countries = useSelector((state) => state.countries.countries)
    const errorUpdatingBusiness = useSelector((state) => state.businesses.errorUpdating)
    const updatingBusiness = useSelector((state) => state.businesses.updating)
    const defMembership = useSelector((state => state.defMembership.defMembership))
    const login = useSelector(state => getLogin(state.account));
    const verificationStatus = useSelector((state) => state.documents.verifStatus);
    const business = useSelector((state) => state.businesses.business);
    const logoUploading = useSelector((state) => state.documents.uploadingBusinessLogo);
    const logoUploaded = useSelector((state) => state.documents.uploadedBusinessLogo);
    const industries = useSelector((state) => state.businesses.industries);

    //Redux Dispatch Actions
    const dispatch = useDispatch();
    const getAllCountries = useCallback(options => { dispatch(CountryActions.countryAllRequest(options)) }, [dispatch])
    const updateBusiness = (businessId, business) => { dispatch(BusinessActions.businessUpdateRequest(businessId, business)) }
    const getMembership = useCallback(() => dispatch(DefMembershipActions.defMembershipRequest()), [dispatch]);
    const uploadBusinessLogo = useCallback((businessId, file) => dispatch(DocumentActions.businessLogoUploading(businessId, file)), [dispatch]);
    const getIndustries = useCallback((industry) => { dispatch(BusinessActions.businessIndustriesRequest(industry)) }, [dispatch])

    const { t } = useTranslation("common");
    const businessProps = props.business
    const [previewLogo, setPreviewLogo] = useState(null);
    const [wrongFile, setWrongFile] = useState(false);
    const [imageDimensions, setImageDimensions] = useState({});
    const [wrongDimensions, setWrongDimensions] = useState(false);
    const [saveClicked, setSaveClicked] = useState(false);
    const op1 = useRef(null)
    const toast = useRef(null);
    const [state, setState] = useState({
        countriesData: [],
        regionsData: [],
        countriesOption: [],
        regionsOption: [],
        codes: [],
        uploaded: false,
        requesting: false,
        loading: false,
        unmounted: false,
        success: false,
        countries: null,
        value: false,
        types: [
            { label: t("business.individual"), value: "INDIVIDUAL" },
            { label: t("business.organisation"), value: "ORGANISATION" }
        ],
        displayText: false,
        industryType: null,
        postcode: "",
        transparent: false,
        business: {},
        notFilled: false,
    })
    const { control, formState: { errors }, handleSubmit, watch, register, reset
    } = useForm({
        defaultValues: {
            type: defMembership.business.type,
            country: defMembership.business.country.id,
            regionState: defMembership.business.regionState,
            purpose: defMembership.business.purpose,
            name: defMembership.business.name,
            city: defMembership.business.city,
            phoneNo: defMembership.business.phoneNo,
            email: defMembership.business.email,
            addressLine1: defMembership.business.addressLine1,
            addressLine2: defMembership.business.addressLine2,
            industry: defMembership.business.industry?.id,
            logoUrl: defMembership.business.logoUrl
        }
    });
    // Watch the fields
    const isPhoneNoEmpty = watch("phoneNo") === '';
    const isEmailEmpty = watch("email") === '';
    const isAddressEmpty = watch("addressLine1") === '';

    function getPhoneCodeById(id, countries) {
        const country = countries?.find(c => c.id === id);
        if (country) {
            return country.phoneCode;
        }
        return null;
    }

    const showSuccess = useCallback(() => {
        toast.current.show({ severity: 'success', summary: t("business.update_success_summary"), detail: t("business.update_success"), life: 1000 });
    }, [t])

    const showError = useCallback(() => {
        toast.current.show({ severity: 'error', summary: t("business.update_error_summary"), detail: t("business.update_error"), life: 3000 });
    }, [t])
    const uploadSucess = useCallback(() => {
        toast.current.show({ severity: 'success', summary: t("business.upload_sucess_summary"), detail: t("business.upload_success"), life: 4000 });
    }, [t])

    const resetForm = () => {
        setSaveClicked(false)
        reset();
    };

    useEffect(() => {
        if (business || defMembership) setState(state => ({ ...state, business }))

        if (countries) {
            const { countriesOption, codes, regionsOption } = [...countries].reduce(({ countriesOption, regionsOption, codes }, { id, regions, name, code }) => ({
                countriesOption: [...countriesOption, { value: id, label: name }],
                regionsOption: {
                    ...regionsOption,
                    [id]: regions?.map((region) => ({ value: region.name, label: region.name })), code
                },
                codes: [...codes, code]
            }), { countriesOption: [], regionsOption: {}, codes: [] })
            setState((state) => ({
                ...state,
                countries: countries,
                countriesOption, codes, regionsOption
            }))
            getIndustries('all')
        }
        if (!countries) {
            getAllCountries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries, getAllCountries, business, defMembership])

    useEffect(() => {
        if (!updatingBusiness && state.loading) {
            setState((state) => ({ ...state, loading: false }))
            if (errorUpdatingBusiness) {
                showError()
            }
            else {
                showSuccess()
            }
        }
    }, [errorUpdatingBusiness, showError, showSuccess, state.loading, updatingBusiness]);

    useEffect(() => {
        if (!logoUploading && state.uploaded) {
            setState((state) => ({ ...state, uploaded: false }))
            if (logoUploaded) {
                uploadSucess()
            }
        }
        if (!props.toggle) {
            setState((state) => ({ ...state, displayText: true, notFilled: false }))
        } else {
            setState((state) => ({ ...state, displayText: false }))
        }

        if (isEmailEmpty || isAddressEmpty || isPhoneNoEmpty) {
            setState(state => ({ ...state, notFilled: true }));
        } else {
            setState(state => ({ ...state, notFilled: false }));
        }

    }, [logoUploaded, logoUploading, state.uploaded, uploadSucess, state.displayText, props.toggle, isEmailEmpty, isAddressEmpty, isPhoneNoEmpty])


    const onSubmit = (data) => {
        const doNotProceed = !isPhoneNoValid || !isEmailValid || state.notFilled
        if (doNotProceed) return
        const business = {
            ...data,
            id: props.business.id,
            country: { id: data.country },
            login: login,
            enterprise: props.business.enterprise,
            industry: props.business.industry,
            logoUrl: state.business?.logoUrl,
            postcode: state.postcode,
        };
        updateBusiness(props.business.id, business);
        setState((prev) => {
            return { ...prev, requesting: true, loading: true, success: true, };
        });
    }

    const country = watch("country");
    const phoneNo = watch("phoneNo");
    const email = watch("email");
    const countryCode = business?.country.code;

    const phoneCode = getPhoneCodeById(country, countries);
    const isPhoneNoValid = phoneNo && isValidPhoneNumber(phoneNo, countryCode);
    const isEmailValid = email && validator.isEmail(email);

    const logo = state.business?.logoUrl
    const industry = state.business?.industry?.nameEn

    const validateFileType = (file) => {
        const validTypes = ["image/png"];
        return validTypes.includes(file.type);
    };

    const handleUpload = (e) => {
        const file = e.files[0];

        // Validate file type
        if (!validateFileType(file)) {
            setWrongFile(true);
            setWrongDimensions(false);
            setPreviewLogo(Date.now());
            setState(state => ({ ...state, transparent: false }))
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const image = new Image(); // Create a new Image object

            image.onload = () => {
                const allowedDimensions = { width: 500, height: 500 };
                const dimensions = { width: image.width, height: image.height };
                const isAllowedSize = dimensions.width >= allowedDimensions.width && dimensions.height >= allowedDimensions.height;

                if (isAllowedSize) {
                    // Check for transparency
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.drawImage(image, 0, 0);
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imageData.data;

                    let hasTransparency = false;
                    for (let i = 3; i < data.length; i += 4) {
                        if (data[i] < 255) { // Alpha value less than 255 indicates transparency
                            hasTransparency = true;
                            break;
                        }
                    }

                    if (!hasTransparency) {
                        // If the image does not have transparency, show an error and do not upload
                        setState(state => ({ ...state, transparent: true }))
                        setPreviewLogo(Date.now());
                        setWrongFile(false);
                        setWrongDimensions(false);
                        return;
                    } else {
                        // Handle valid image with transparency
                        const formData = new FormData();
                        formData.append("file", file, file.name); // Preparing data for upload
                        uploadBusinessLogo(business.id, formData);
                        setState((state) => ({ ...state, uploaded: true }));
                        setImageDimensions(dimensions);
                        setPreviewLogo(reader.result);
                        setWrongFile(false);
                        setWrongDimensions(false);
                        setState(state => ({ ...state, transparent: false }))
                    }
                } else {
                    setWrongDimensions(true);
                    setWrongFile(false);
                    setPreviewLogo(Date.now());
                    setState(state => ({ ...state, transparent: false }))
                    return;
                }
            };
            image.src = reader.result;
        };
        reader.readAsDataURL(file); // Read the file if valid
    };
    const renderLogo = () => {
        let imgSrc = logo;
        let altText = t("business.uploadedLogoText");

        if (wrongFile || wrongDimensions || state.transparent) {
            imgSrc = logo;
        } else if (previewLogo) {
            imgSrc = previewLogo;
        }
        return (
            <div style={{ marginTop: '10px' }}>
                <img src={imgSrc} alt={altText} className='logo-container' />
            </div>
        );
    };

    const disableField = business?.verified || (!business?.verified && !verificationStatus) || (verificationStatus > 0 || !props.toggle)

    return (!state.countries ? <Loading /> :
        <>
            <Toast ref={toast} onRemove={(message) => {
                if (message.severity === "success") {
                    getMembership()
                }
            }} />
            {businessProps?.verified &&
                <div className="info-div">
                    <span className="info-text" >{t("business.info")}</span>
                    <span className="info-span" >{t("business.info_span")}</span>
                    <div style={{ display: "flex" }}>
                        <span className="info-name" >{businessProps.name}</span>
                        {businessProps.enterprise ? <span className="info-type" >{t("enterprise")}</span> : null}
                    </div>
                </div>

            }
            {!state.displayText && <div className='payment-added-alert col-12'><span className='payment-added-alert1'>{t("business.existing_edit1")}</span> <br /> <span className='payment-added-alert2'>{t("business.existing_edit2")}</span> </div>}
            {defMembership && <div className={classNames("wizard-card-content register active-content")} >
                <form onSubmit={handleSubmit(onSubmit)}
                    className="wizard-forms-wrapper p-grid p-nogutter settings-edit-form">
                    <div className="card" style={{ marginBottom: "1rem", marginTop: "0" }}>
                        <div className='p-2 upload-container'>
                            {renderLogo()}
                            <div >
                                <div style={{ display: "flex" }}>
                                    <FileUpload
                                        mode="basic" className='upload-button'
                                        key={previewLogo ? previewLogo : imageDimensions ? imageDimensions : wrongFile ? wrongFile : null}
                                        chooseLabel={logo ? t("business.change_logo") : t("business.upload_button")}
                                        customUpload auto={true}
                                        accept="image/png" uploadHandler={handleUpload}
                                    />
                                    <div className='transparent'><i className="pi pi-info-circle info-icon" style={{ color: "#9747ff", fontSize: "12px" }} onClick={(e) => { op1.current.toggle(e) }}></i> <span className='payment_token'>{t("business.transparent_logo")}</span></div>
                                    <OverlayPanel ref={op1}>
                                        <div className="">
                                            <div className='mb-3 mt-2'>
                                                <div className='instruction2-header mb-2'>{t("business.transparent_logo_header")}</div>
                                                <div>
                                                    <div className='instruction2 mb-2'>{t("business.transparent_logo_text1")}<a href='http://remove.bg/' target="blank" className='instruction1-inner remove-link'>{t("business.transparent_logo_text2")}</a></div>
                                                    <div className='instruction2 mb-2'>{t("business.transparent_logo_text3")}  <span className='instruction1-inner'>{t("business.transparent_logo_text4")}</span> {t("business.transparent_logo_text5")}</div>
                                                    <img className='instruction2 mb-2' src={removeBG} alt={t("business.transparent_logo_text8")} style={{ border: "0.5px solid black" }} />
                                                    <div className='instruction2 mb-2'>{t("business.transparent_logo_text6")} <span className='instruction1-inner'>{t("business.transparent_logo_text7")}</span></div>
                                                </div>
                                            </div>

                                        </div>
                                    </OverlayPanel>
                                    {wrongFile &&
                                        <div className='level'>
                                            <i className="pi pi-times-circle i_times_circle" />
                                            <span className="png-text-1">{t("business.invalid_upload_1")}</span>
                                            <span style={{ display: "flex" }}>{t("business.invalid_text_1")}</span>
                                        </div>
                                    }
                                    {wrongDimensions &&
                                        <div className='level'>
                                            <i className="pi pi-times-circle i_times_circle" />
                                            <span className="png-text-1">{t("business.invalid_upload_2")}</span>
                                            <span style={{ display: "flex" }}>{t("business.invalid_text_2")}</span>
                                        </div>
                                    }
                                    {state.transparent &&
                                        <div className='level'>
                                            <i className="pi pi-times-circle i_times_circle" />
                                            <span className="png-text-1">{t("business.invalid_upload_3")}</span>
                                            <span style={{ display: "flex" }}>{t("business.invalid_text_3")}</span>
                                        </div>
                                    }
                                </div>
                                {!(wrongDimensions || wrongFile || state.transparent) && <div className='png-cont'>
                                    <span className="png">{t('business.png_text_1')}</span>
                                    <p className="png-text">{t('business.png_text_2')}</p>
                                </div>}
                            </div>
                        </div>
                        <hr style={{ backgroundColor: "#C1C1C9", height: "1.2px" }} />
                        <div className='grid'>
                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                                <label
                                    htmlFor="merchant_type"
                                    className="label label-styles"
                                    >
                                    {t("business.merchant_type")}<span style={{ color: "red" }}>*</span>
                                </label>
                                {state.displayText ? <span className='business_info_text'>{business?.type}</span> :
                                    <InputText
                                        disabled={business?.verified || (!business?.verified && !verificationStatus) || (verificationStatus > 0 || !props.toggle)}
                                        id="merchant_type"
                                        value={state.types.label}
                                        placeholder={props.business?.type}
                                        className="drop_down business_inputs"
                                        onChange={(event) => { setState((prev) => { return { ...prev, type: event.value } }) }}
                                    />

                                }
                            </div>

                            {industries && <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                                <label
                                    htmlFor="industry"
                                    className="label label-styles"
                                    >
                                    { t("business.industry_type")}<span style={{ color: "red" }}>*</span>
                                </label>
                                {state.displayText ? <span className='business_info_text'>{industry}</span>
                                    : <>
                                        <Controller name="industry"
                                            rules={{ required: t("business.error_industry_required") }} control={control} render={({ field, fieldState }) => (
                                                <Dropdown
                                                    id='industry'
                                                    className={disableField ? 'disabled-dropdown' : ''}
                                                    options={[...industries].sort((a, b) => a.nameEn?.localeCompare(b.nameEn))}
                                                    disabled={disableField}
                                                    optionLabel="nameEn"
                                                    optionValue="id"
                                                    placeholder={t("business.select_industry")}
                                                    value={field.value} onChange={(e) => field.onChange(e.value)} />
                                            )} />
                                    </>
                                }
                            </div>}

                        </div>
                        <hr style={{ backgroundColor: "#C1C1C9", height: "1px" }} />
                        <span className="info-text" >{t("business.info_2")}</span>
                        <div className="grid">
                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                                <label
                                    htmlFor="country"
                                    className="label label-styles"
                                    >
                                    { t("business.country")}<span style={{ color: "red" }}>*</span>
                                </label>
                                {state.displayText ? <span className='business_info_text'>{business?.country.name}</span> :
                                    <Controller name="country"
                                        rules={{ required: t("business.error_country_required") }} control={control} render={({ field }) => (
                                            <Dropdown
                                                id='country_business'
                                                className={disableField ? 'disabled-dropdown' : ''}
                                                options={state.countriesOption}
                                                disabled={disableField}
                                                {...register("country")}
                                                placeholder={t("business.choose_country")}
                                                value={field.value} onChange={(e) => field.onChange(e.value)} />
                                        )} />}
                            </div>

                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms" >
                                <label
                                    htmlFor="business_address1-required"
                                    className={"label"}
                                    style={{ color: errors.addressLine1 ? "red" : "#000000", fontFamily: "Roboto", fontSize: "11px" }}>
                                    {errors.addressLine1 ? t("business.error_address1_required") : t("business.address_1")}
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                {state.displayText ? <span className='business_info_text'>{business?.addressLine1}</span> :
                                    <Controller
                                        name={"addressLine1"}
                                        control={control}
                                        render={({ field, fieldState }) => (<>
                                            <InputText
                                                id='address1'
                                                {...field}
                                                {...register("addressLine1")}
                                                disabled={!props.toggle}
                                                className={classNames({ "p-invalid": fieldState.invalid })} />
                                            <>
                                                {(isAddressEmpty && saveClicked) && <div className='level-2'><span className="png-text-1">{t("business.error_address1_required")}</span>
                                                </div>}
                                            </>
                                        </>)}
                                    />}
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                                <label
                                    htmlFor="business_address2-required"
                                    className={"label"}
                                    style={{ color: "#000000", fontFamily: "Roboto", fontSize: "11px" }}>{t("business.address_2")}</label>
                                    {state.displayText ? <span className='business_info_text'>{business?.addressLine2}</span> :
                                    <Controller
                                        name={"addressLine2"}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id='address2'
                                                {...field}
                                                {...register("addressLine2")}
                                                disabled={!props.toggle}
                                                className={classNames({ "p-invalid": fieldState.invalid })}
                                            />
                                        )}
                                    />}

                            </div>

                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                                <label
                                    htmlFor="postcode"
                                    className="label"
                                    style={{ color: "#000000" }}
                                >{t("business.postcode")}
                                </label>
                                {state.displayText ? <span className='business_info_text'>{null}</span> :
                                    <InputText
                                        id="business_postcode1"
                                        placeholder={t("business.postcode_placeholder")}
                                        value={state.postcode}
                                        onChange={({ target }) =>
                                            setState((prev) => { return { ...prev, postcode: target.value, } })}
                                    />}
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
                                <label
                                    htmlFor="regionState"
                                    className="label"
                                    style={{ color: errors.regionState ? "red" : "#000000", fontFamily: "Roboto", fontSize: "11px" }}>
                                    {t("business.regState")}<span style={{ color: "red" }}>*</span>
                                </label>
                                {state.displayText ? <span className='business_info_text'>{business?.regionState}</span> :
                                    <Controller name="regionState"
                                        rules={{ required: t("business.error_region_state_required") }}
                                        control={control} render={({ field }) => (
                                            <Dropdown
                                                className="dropdown"
                                                options={state.regionsOption[watch("country")]}
                                                id='regionState'
                                                {...register("regionState")}
                                                placeholder={t("business.choose_region")} disabled={!props.toggle}
                                                value={field.value} onChange={(e) => field.onChange(e.value)} />
                                        )} />}
                            </div>

                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms city">
                                <label
                                    htmlFor="business_city-required"
                                    className="label"
                                    style={{
                                        color: errors.city ? "red" : "#000000", fontFamily: "Roboto", fontSize: "11px"
                                    }}>
                                    {errors.city ? t("business.error_city_required") : t("business.city")}
                                </label>
                                {state.displayText ? <span className='business_info_text'>{business?.city}</span> :
                                    <Controller
                                        name={"city"}
                                        control={control}
                                        rules={{ required: t("business.error_city_required") }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id='city'
                                                {...field}
                                                {...register("city")}
                                                placeholder="Accra"
                                                disabled={!props.toggle}
                                                className={classNames("form-control", { "p-invalid": fieldState.invalid })}
                                            />
                                        )}
                                    />}
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms" >
                                <label
                                    htmlFor="phoneNo"
                                    className="label"
                                    style={{ color: errors.phoneNo ? "red" : "#000000", fontFamily: "Roboto", fontSize: "11px" }}>
                                    {t("common.phone_no")}<span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="phone-group">
                                    {state.displayText ? <div>
                                        <span className='business_info_text'>{business?.phoneNo}</span>
                                        <div>
                                            <span className='label label-bold'>{t('business.phone_tag_text1')}</span>
                                            <span className='label'>{t('business.phone_tag_text2')}</span>
                                            <span className='label' style={{ fontWeight: "bold" }}>{t('business.phone_tag_text3')}</span>
                                        </div></div>
                                        : <>
                                            <div><p className='phoneCode'>{phoneCode}</p></div>
                                            <Controller
                                                name={"phoneNo"}
                                                control={control}
                                                rules={{
                                                    required: t("business.error_phone_required"),
                                                    pattern: {
                                                        // eslint-disable-next-line no-useless-escape
                                                        value: /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                                                    },
                                                }}
                                                render={({ field, fieldState }) => (<div>
                                                    <>
                                                        <InputText
                                                            {...field}
                                                            international="false"
                                                            countrycallingcodeeditable="true"
                                                            limitmaxlength={state.value.toString()}
                                                            addinternationaloption="false"
                                                            maxLength={10}
                                                            error={errors.phoneNo}
                                                            id="phoneNo"
                                                            name="phoneNo"
                                                            control={control}
                                                            country="GH"
                                                            style={{width:"26.5rem"}}
                                                            countries={state.codes}
                                                            disabled={!watch("country") || !props.toggle}
                                                            placeholder={t("business.enter_phone_no")}
                                                            defaultcountry={state.regionsOption[+watch("country")]?.code}
                                                            className={classNames({ "p-invalid": fieldState.isPhoneNoValid })}
                                                             />
                                                    </>
                                                    <div>
                                                    {((!isPhoneNoValid || isPhoneNoEmpty) && saveClicked) && <div className='level-1'><span className="png-text-4">{t("business.error_phone_invalid")}</span>
                                                    </div>}
                                                    </div></div>
                                                )}
                                            />
                                        </>}
                                </div>
                            </div>

                            <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms" >
                                <label
                                    htmlFor="email"
                                    className="label"
                                    style={{ color: errors.email ? "red" : "#000000" }}>
                                    {errors.email ? errors.email.message : t("common.email")}<span style={{ color: "red" }}>*</span>
                                </label>
                                {state.displayText ? <div>
                                    <span className='business_info_text'>{business?.email}</span>
                                    <div>
                                        <span className='label label-bold'>{t('business.email_tag_text1')}</span>
                                        <span className='label'>{t('business.email_tag_text2')}</span>
                                        <span className='label' style={{ fontWeight: "bold" }}>{t('business.email_tag_text3')}</span>
                                    </div>
                                </div> :
                                    <Controller
                                        name={"email"}
                                        control={control}
                                        rules={{
                                            required: t("business.error_email_required"),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            },
                                        }}
                                        render={({ field, fieldState }) => (<>
                                            <InputText
                                                id='email'
                                                {...field}
                                                placeholder="reveknew@gmail.com"
                                                disabled={!props.toggle}
                                                className={classNames("form-control email-field", { "p-invalid": fieldState.invalid })} maxLength={50} />
                                            <>
                                                {((!isEmailValid || isEmailEmpty) && saveClicked) && <div className='level-2'><span className="png-text-1">{t("business.error_email_invalid")}</span>
                                                </div>}
                                            </>
                                        </>)}
                                    />}
                            </div>
                        </div>
                        <hr style={{ backgroundColor: "#C1C1C9", height: "1px" }} />
                        <div className="grid">
                            <div className="pcol-12 sm:col-12 md:col-12 lg:col-6 wizard-forms" style={{ width: "auto" }}>
                                <div className="p-mb-8"></div>
                                <h6 className="label purpose"
                                    style={{ color: errors.purpose ? "red" : "#00000" }} >
                                    {t("business.choose_purpose")}
                                </h6>
                                {state.displayText ? <span>{business?.purpose}</span> :
                                    <Controller
                                        name={"purpose"}
                                        control={control}
                                        render={({ field }) => (<>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div className="p-col-12">
                                                    <RadioButton
                                                        value="SERVICES"
                                                        id={field.name}
                                                        disabled={business?.verified || !props.toggle}
                                                        onChange={field.onChange}
                                                        checked={watch("purpose") === "SERVICES"}
                                                    />
                                                    <label
                                                        htmlFor="rb1"
                                                        className="p-radiobutton-label"
                                                        style={{ color: "#666678" }}>
                                                        {t("business.purpose_SERVICES")}
                                                    </label>
                                                </div>
                                                <br></br>
                                                <div className="p-col-12 " style={{ marginLeft: "5rem" }}>
                                                    <RadioButton
                                                        id={field.name}
                                                        disabled={business?.verified || !props.toggle}
                                                        onChange={field.onChange}
                                                        value="DONATION"
                                                        checked={watch("purpose") === "DONATION"}
                                                    />
                                                    <label
                                                        htmlFor="rb2"
                                                        className="p-radiobutton-label"
                                                        style={{ color: "#666678" }} >
                                                        {t("business.purpose_DONATION")}
                                                    </label>
                                                </div>
                                            </div>
                                        </>)}
                                    />}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        {props.toggle && <Button
                            style={{ backgroundColor: "#D0021B", alignItems: "center" }}
                            label='Cancel'
                            onClick={() => {
                                resetForm()
                                props.onToggle()
                            }}
                        />}
                        <Button
                            className="save-button p-button-info"
                            id='edit_button'
                            loading={updatingBusiness}
                            label={!props.toggle ? "Edit" : "Save"}
                            type={props.toggle ? 'button' : "submit"}
                            onClick={() => {
                                if (!isPhoneNoValid || !isEmailValid || state.notFilled) {
                                    setSaveClicked(true)
                                    return;
                                };
                                props.onToggle();
                            }}
                        />
                    </div>
                </form>
            </div>}
        </>
    )
}
export default BusinessEdit