import React from 'react'
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';

const SummaryCards = ({ loading, style, title, value, subDesc, cardInfo }) => {

    const titleValue = (val, title) => {
        if ((title === "Subscriptions" || title === "Payments" || title === "Withdrawals") && val) {
            return "";
        }
        else if ((title === "Subscriptions" || title === "Payments" || title === "Withdrawals") && !val) {
            return "";
        }
        else if ((title !== "Subscriptions" || title !== "Payments" || title !== "Withdrawals") && val) {
            return val
        }
        else if ((title !== "Subscriptions" || title !== "Payments" || title !== "Withdrawals") && !val) {
            return 0
        }
    }

    return (
        <div>
            <div style={{ marginRight: "1.5rem" }}
                className="p-col-12 p-lg-6 p-xl-3 reports-card-content " id='reports-card-content'
            >
                <div className='grid' style={{ marginTop: "5px" }}>
                    <div className='col-10'> <span className={`card-titles + ${style}`}>{title}</span></div>
                    <Tooltip target=".custom-target-icon" />
                    <div className='col-2'>
                        {cardInfo && <i className='custom-target-icon pi pi-info-circle'
                            style={{ cursor: "pointer" }}
                            data-pr-tooltip={cardInfo}>
                        </i>}
                    </div>
                    <div className="summary-card-info">
                        <span className="summary-card-text">{titleValue(value, title)}</span>
                        <span className="summary-card-subDesc">{subDesc} </span>
                    </div>
                </div>
                {
                    loading ? <div className="card flex justify-content-center">
                        <ProgressSpinner id="progress-spinner" />
                    </div> : <></>
                }
            </div>
        </div>
    )
}

export const SummarySection = ({ summary, header = "", loading, }) => {
    return (
        <>
            <span className="wallet-titles summary-title">{header}</span>
            <div className='report_cards'>
                {
                    summary.map((summaryContent, i) => (
                        <SummaryCards{...summaryContent} loading={loading} key={i} />
                    ))
                }
            </div>
        </>
    )
}
export default SummaryCards;