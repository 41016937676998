import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import "../../Styles/pages/product.scss"
import { useTranslation, Trans } from "react-i18next";
import { classNames } from "primereact/utils";
import { useSelector, useDispatch } from "react-redux";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import PlanActions from "../../Redux/transition/plan/plan.reducer";

function PriceChange(props) {

    const { visible, onHide, toggleState, oldPrice, id, defMembership, callbackFunctions } = props

    const changingPrice = useSelector((state) => state.plans.changingPrice)
    const changingSuccess = useSelector((state) => state.plans.changingSuccess)
    const errorChanging = useSelector((state) => state.plans.errorChangingPrice)

    const dispatch = useDispatch()
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
    const changePlanPrice = useCallback((planId, amount) => { dispatch(PlanActions.planPriceChangeRequest(planId, amount)) }, [dispatch])

    const toast = useRef(null);
    const { t } = useTranslation("common");
    const [state, setState] = useState({
        success: false,
        setDisplayBasic: false,
        visible: false,
    })

    useEffect(() => {
        if (!changingPrice && state.success && changingSuccess) {
            showSuccess();
            setState((state) => { return { ...state, success: false, } });
        }
        if (!changingPrice && state.success && errorChanging) {
            showError();
            setState((state) => { return { ...state, success: false, } });
        }
        if (visible) {
            setState((state) => { return { ...state, visible: visible } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changingPrice, state.success, changingSuccess, errorChanging, visible])

    const showSuccess = (data) => {
        toast.current.show({ severity: 'success', code: "priceChange", summary: t("product.success_summary"), detail: t('product.price_change_successful'), life: 3000 });
    }

    const showError = (data) => {
        toast.current.show({ severity: 'error', summary: t("product.error_summary"), detail: t("product.archive_error"), life: 3000 });
    }

    const formik = useFormik({
        initialValues: {
            amount: ""
        },
        validate: (data) => {
            let errors = {};

            if (!data.amount) {
                errors.amount = t("products.validate_error");
            }
            return errors;
        },

        onSubmit: (data) => {
            const planData = {
                amount: data.amount
            };
            changePlanPrice(id, planData);
            setState({ ...state, success: true, });
        },
    });

    const isFormFieldValid = (amount) =>
        !!(formik.touched[amount] && formik.errors[amount]);

    const getFormErrorMessage = (amount) => {
        return (
            isFormFieldValid(amount) && (
                <small className="p-error">{formik.errors[amount]}</small>
            )
        );
    };

    const baseStyle = { fontWeight: "bold", fontSize: "16px" };
    const getStyle = (color) => ({ ...baseStyle, color });

    return (
        <>
            <Dialog
                header={t("product.change_price_1")}
                visible={toggleState}
                onHide={() => { onHide() }}
                className="tier_dialog" id="tier_dialog"
            >
                <Toast
                    className="p-toast p-toast-container p-toast-item p-toast-message p-toast-title "
                    ref={toast}
                    onRemove={(message) => {
                        if (message.code === 'priceChange') {
                            onHide()
                            getActivePlanGroups(defMembership.business.id)
                            setState({ ...state, success: false, });
                            formik.resetForm();
                            callbackFunctions()
                        }
                    }}
                />

                <form onSubmit={formik.handleSubmit} className="p-fluid form-style">
                    <div className="p-field mt-2">
                        <p>
                            <Trans
                                i18nKey="product.change-hint"
                                components={{1: <span style={getStyle("#D0021B")} />, 2: <span  style={getStyle("#006644")}/>, 3: <span style={getStyle("#000")}/>,}}
                            >
                            </Trans>
                        </p>
                        <hr style={{ backgroundColor: "#ced4da", height: "1.7px", margin: "1rem 0rem 1.5rem 0rem" }} />
                        <p className='font-bold old-amt'>{t("products.old_amount")} {`(${defMembership.business.currency})`}</p>
                        <div className="p-float-label">
                            <InputText
                                id="old_amount"
                                name="old_amount"
                                disabled
                                value={oldPrice}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "main-amt":true,
                                    "p-invalid": isFormFieldValid("name"),
                                })}
                            />
                        </div>
                        {getFormErrorMessage("name")}
                    </div><br />
                    <div className="p-field">
                        <p className={classNames({
                                    "old-amt": true,
                                    "font-bold": true,
                                    "p-error": isFormFieldValid("amount"),
                                })}
                            >
                                {t("products.new_amount")} {`(${defMembership.business.currency})`}
                            </p>
                            <InputText
                                id="amount"
                                name="amount"
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                className= "main-amt"
                                placeholder= {t("amount")}
                            />
                            
                        </div>
                    <div className="btn-div">
                        <Button
                            type="submit"
                            disabled={changingPrice}
                            loading={changingPrice}
                            label={t("products.save_product")}
                            className="btn-style"
                        />
                    </div>
                </form>
            </Dialog>
        </>
    )
}
export default PriceChange;