import React from 'react'
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';

const ProductCards = ({ style, title, value, subDesc, cardInfo, className }) => {

      const titleValue = (val, title) => {
            if ((title === "Subscriptions" || title === "Payments" || title === "Withdrawals") && val) {
                  return "";
            }
            else if ((title === "Subscriptions" || title === "Payments" || title === "Withdrawals") && !val) {
                  return "";
            }
            else if ((title !== "Subscriptions" || title !== "Payments" || title !== "Withdrawals") && val) {
                  return val
            }
            else if ((title !== "Subscriptions" || title !== "Payments" || title !== "Withdrawals") && !val) {
                  return 0
            }
      }
      return (
            <div className='col-3'>
                  <div className="product_cards">
                        <div className='grid'>
                              <div className="col-10" style={{ display: "flex", flexDirection: "column" }}>
                                    <span className={`product-card-titles + ${style} ${className}`}>{title}</span>
                                    <span className="product-card-text">{titleValue(value, title)}</span>
                                    <span className="product-summary-card-subDesc">{subDesc}</span>
                              </div>

                              <div className="col-2">
                                    <Tooltip target=".custom-target-icon" />
                                    {cardInfo && <i className='custom-target-icon pi pi-info-circle' id='card-info-btn' data-pr-tooltip={cardInfo}>
                                    </i>}
                              </div>
                        </div>
                  </div>
            </div>
      )
}

const ProductSummaryCards = ({ style, title, cardInfo, type,
      subsNewValue, subtitleNew, subsActiveValue, subtitleActive, subtitleCompleted,
      subsCompletedValue, subsCancelledValue, subtitleCancelled, subsPausedValue, subtitlePaused,
      missedValue, missedSubDesc, revenueValue, revenueSubDesc, collectedValue, collectedSubDesc,
      loading, subsActiveDesc, subsPausedDesc, subsNewDesc, subsCancelledDesc, subsCompletedDesc,
      processedWithdrawalValue, reversedWithdrawalValue, pendingWithdrawalValue, reversedWithdrawalDesc,
      pendingWithdrawalDesc, processedWithdrawalDesc,subCompletedDesc,subActiveDesc,subCancelledDesc,
      subPausedDesc,pendingSevenDayValue,pendingSevenDay,missedSevenDayValue,missedSevenDay,
      revenueSevenDayValue,revenueSevenDay,collectedSevenDayValue,collectedSevenDay,pendingthirtyDayValue,
      pendingthirtyDay,missedthirtyDayValue,missedthirtyDay,revenuethirtyDayValue,revenuethirtyDay,
      collectedthirtyDayValue,collectedthirtyDay,pendingLifetimeValue,pendingLifetime,missedLifetimeValue,
      missedLifetime,revenueLifetimeValue,revenueLifetime,collectedLifetimeValue,collectedLifetime
}) => {
      return (
            <div className="col-4">
                  <div className='products-report-content' >
                        <div className='grid'>
                              <div className='col-10'> <span className={`card-titles + ${style}`}>{title}</span></div>
                              <Tooltip target=".custom-target-icon" />
                              <div className='col-2'>
                                    {cardInfo && <i className='custom-target-icon pi pi-info-circle' id='card-info-btn'
                                          data-pr-tooltip={cardInfo}>
                                    </i>}
                              </div>
                        </div>
                        <div className="summary-records">
                              {type === "subs" && <div>
                                    <div className='grid'>
                                          <div className='col-6'>
                                                <div className="column-view column-view-mb">
                                                      <span className="green-success-txts">{subtitleNew}</span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsNewValue}</span>&nbsp;
                                                            {/* <span className="subsTitle">{subsNewDesc}</span> */}
                                                            {subsNewValue > 1 ? <span className="subsTitle">{subsNewDesc}</span> :
                                                                  <span className="subsTitle">{subActiveDesc}</span>}
                                                      </div>
                                                </div>

                                                <div className="column-view column-view-mb">
                                                      <span className="yellow-progress-txts">{subtitlePaused}</span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsPausedValue}</span>&nbsp;
                                                            {/* <span className="subsTitle">{subsPausedDesc}</span> */}
                                                            {subsPausedValue > 1 ? <span className="subsTitle">{subsPausedDesc}</span> :
                                                                  <span className="subsTitle">{subPausedDesc}</span>}
                                                      </div>
                                                </div>

                                                <div className="column-view">
                                                      <span className="red-cancelled-txts">{subtitleCancelled}</span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsCancelledValue}</span>&nbsp;
                                                            {/* <span className="subsTitle">{subsCancelledDesc}</span> */}
                                                            {subsCancelledValue > 1 ? <span className="subsTitle">{subsCancelledDesc}</span> :
                                                                  <span className="subsTitle">{subCancelledDesc}</span>}
                                                      </div>
                                                </div>
                                          </div>

                                          <div className='col-6'>
                                                <div className="column-view column-view-mb">
                                                      <span className="green-success-txts">{subtitleActive}</span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsActiveValue}</span>&nbsp;
                                                            {/* <span className="subsTitle">{subsActiveDesc}</span> */}
                                                            {subsActiveValue > 1 ? <span className="subsTitle">{subsActiveDesc}</span> :
                                                                  <span className="subsTitle">{subActiveDesc}</span>}
                                                      </div>
                                                </div>

                                                <div className="column-view">
                                                      <span className="grey-completed-txt">{subtitleCompleted} </span>
                                                      <div className="subs-flex-row">
                                                            <span className="subsValues">{subsCompletedValue}</span>&nbsp;
                                                            {/* <span className="subsTitle">{subsCompletedDesc}</span> */}
                                                            {subsCompletedValue > 1 ? <span className="subsTitle">{subsCompletedDesc}</span> :
                                                                  <span className="subsTitle">{subCompletedDesc}</span>}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {type === "payments" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div >
                                                      <span className="green-success-txts">{collectedSubDesc}</span>
                                                      <span>{collectedValue}</span>
                                                </div>
                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="red-cancelled-txts">{missedSubDesc}</span>
                                                      <span>{missedValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <span className="blue-revenue-txt">{revenueSubDesc}</span>
                                                <span>{revenueValue}</span>
                                          </div>
                                    </div>
                              </div>}

                              {type === "withdrawals" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div>
                                                      <span className="green-success-txts">{processedWithdrawalDesc}</span>
                                                      <span>{processedWithdrawalValue}</span>
                                                </div>

                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="red-cancelled-txts">{reversedWithdrawalDesc}</span>
                                                      <span>{reversedWithdrawalValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <div>
                                                      <span className="yellow-progress-txts">{pendingWithdrawalDesc}</span>
                                                      <span>{pendingWithdrawalValue}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>}
                              {type === "payments-7" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div>
                                                      <span className="green-success-txts">{collectedSevenDay}</span>
                                                      <span>{collectedSevenDayValue}</span>
                                                </div>

                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="yellow-progress-txts">{pendingSevenDay}</span>
                                                      <span>{pendingSevenDayValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <div>
                                                      <span className="blue-revenue-txt">{revenueSevenDay}</span>
                                                      <span>{revenueSevenDayValue}</span>
                                                </div>
                                                <div style={{ marginTop: "1rem" }}> 
                                                      <span className="red-cancelled-txts">{missedSevenDay}</span>
                                                      <span>{missedSevenDayValue}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {type === "payments-30" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div>
                                                      <span className="green-success-txts">{collectedthirtyDay}</span>
                                                      <span>{collectedthirtyDayValue}</span>
                                                </div>

                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="yellow-progress-txts">{pendingthirtyDay}</span>
                                                      <span>{pendingthirtyDayValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <div>
                                                      <span className="blue-revenue-txt">{revenuethirtyDay}</span>
                                                      <span>{revenuethirtyDayValue}</span>
                                                </div>
                                                <div style={{ marginTop: "1rem" }}> 
                                                      <span className="red-cancelled-txts">{missedthirtyDay}</span>
                                                      <span>{missedthirtyDayValue}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {type === "lifetime" && <div>
                                    <div className="grid">
                                          <div className="col-6">
                                                <div>
                                                      <span className="green-success-txts">{collectedLifetime}</span>
                                                      <span>{collectedLifetimeValue}</span>
                                                </div>

                                                <div style={{ marginTop: "1rem" }}>
                                                      <span className="yellow-progress-txts">{pendingLifetime}</span>
                                                      <span>{pendingLifetimeValue}</span>
                                                </div>
                                          </div>
                                          <div className="col-6">
                                                <div>
                                                      <span className="blue-revenue-txt">{revenueLifetime}</span>
                                                      <span>{revenueLifetimeValue}</span>
                                                </div>
                                                <div style={{ marginTop: "1rem" }}> 
                                                      <span className="red-cancelled-txts">{missedLifetime}</span>
                                                      <span>{missedLifetimeValue}</span>
                                                </div>
                                          </div>
                                    </div>
                              </div>}

                              {loading ? <div className="card flex justify-content-center">
                                    <ProgressSpinner id="progress-spinner" />
                              </div> : <></>}
                        </div>
                  </div>
            </div>
      )
}

export const SummarySubscriptionSection = ({ summary, loading, }) => {
      return (
            <>
                  {summary.map((summaryContent, index) => (
                        <ProductCards{...summaryContent} loading={loading} key={index} />
                  ))}
            </>
      )
}

export const ProductsSummarySection = ({ summary, header = "", loading, }) => {
      return (
            <>
                  <span className="wallet-titles summary-title">{header}</span>
                  {
                        summary.map((productsSummaryContent, i) => (
                              <ProductSummaryCards{...productsSummaryContent} loading={loading} key={i} />
                        ))
                  }
            </>
      )
}