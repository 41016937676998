import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import BusinessCustomersActions from '../../Redux/transition/customers/business-customer.reducer';
import SubscriberActions from "../../Redux/transition/subscriber/subscriber.reducer";
import PaymentTrackActions from "../../Redux/transition/payment-track/payment-track.reducer";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import { useHistory } from "react-router-dom";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import "../../Styles/pages/subscriptionDetail.scss"
import "../../Styles/pages/activities.scss";
import "../../Styles/pages/customers.scss";
import "../../Styles/pages/product.scss"
import CustomDataTable from "../../Components/CustomDataTable";
import moment from "moment";
import CustomerEdit from "../Customers/edit";
import InviteSubscriber from "../Subscriptions/Invitation/InviteSubscriber";
import { formatTime } from "../../Pages/Subscriptions/Constant"
import { Paginator } from 'primereact/paginator';
import MarkAsPaid from "../../Components/MarkAsPaid";

const BusinessCustomers = () => {

    const { t } = useTranslation("common");
    const history = useHistory();
    const customerId = history.location.pathname.split("/").pop();

    const [displayBasic, setDisplayBasic] = useState(false);
    const [visible, setVisible] = useState(false)
    const cancelToast = useRef(null)
    const pauseToast = useRef(null)
    const resumeToast = useRef(null)
    const rescheduleToast = useRef(null)
    const [confirmPause, setConfirmPause] = useState(false)
    const [confirmResume, setConfirmResume] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [confirmReschedule, setConfirmReschedule] = useState(false)
    const [date, setDate] = useState(null);
    const [activeTab, setActiveTab] = useState(0)
    const [visibleInvite, setVisibleInvite] = useState(false);

    const [state, setState] = useState({
        businessCustomers: null,
        count: null,
        page: 0,
        sort: "id,asc",
        loading: true,
        businessCustomerSubscriptions: [],
        businessCustomerSubscriptionAmounts: null,
        customerRecord: {
            id: "",
            firstName: "",
            lastName: "",
            customerNum: "",
            email: "",
        },
        recentPaymentsObject: [],
        recentMissedPaymentsObject: [],
        recentSubscriptionActivityObject: [],
        planGroup: {},
        visibleCreate: false,
        visible: false,
        totalPendingAmount: null,
        totalMissedAmount: null,
        customerSummary: [],
        paused: false,
        cancelled: false,
        resumed: false,
        subscriptionId: null,
        updating: false,
        firstName: "",
        lastName: "",
        customerNum: "",
        email: "",
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        data: null,
        key: false,
        rescheduled: false,
        successfulPaymentsObject: {},
        expiredPaymentsObject: {},
        id: null,
        customerExternalPayments: [],
        flex: null,
        first: 0,
        colSort: 'receivedAt,desc',
        extSort: "externallyReceivedOn,desc",
        expSort: "scheduledFor,desc",
        size: 15,
        tabChange: true
    })

    const routeChange = (pathname, state) => {
        history.push({
            pathname,
            state,
        });
    };

    //Redux State from Store
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const businessCustomerSubscriptions = useSelector((state) => state.businessCustomers.businessCustomerSubcriptions)
    const fetchingCustomerSubscriptions = useSelector((state) => state.businessCustomers.fetchingbusinessCustomerSubscriptions)
    const fetchingCustomerCollectedPayments = useSelector((state) => state.businessCustomers.fetchingSuccessfulPayments)
    const businessCustomerRecord = useSelector((state) => state.businessCustomers.businessCustomerRecord)
    const fetchingCustomerRecord = useSelector((state) => state.businessCustomers.fetchingBusinessCustomerRecord)
    const recentPayments = useSelector((state) => state.businessCustomers.recentPayments)
    const recentMissedPayments = useSelector((state) => state.businessCustomers.recentMissedPayments)
    const recentSubscriptionActivity = useSelector((state) => state.businessCustomers.recentSubscriptionActivity)
    const customerPendingPayments = useSelector((state) => state.businessCustomers.customerPendingPayments)
    const customerMissedPayments = useSelector((state) => state.businessCustomers.customerMissedPayments)
    const customerReceivedPayments = useSelector((state) => state.businessCustomers.customerReceivedPayments)
    const cancelledSubscription = useSelector((state) => state.subscribers.cancelledSubscription)
    const cancellingSubscription = useSelector((state) => state.subscribers.cancellingSubscription)
    const pausingSubscription = useSelector((state) => state.subscribers.pausingSubscription)
    const pausedSubscription = useSelector((state) => state.subscribers.pausedSubscription)
    const resumingSubscription = useSelector((state) => state.subscribers.resumingSubscription)
    const resumedSubscription = useSelector((state) => state.subscribers.resumedSubscription)
    const rescheduledSubscription = useSelector((state) => state.subscribers.rescheduledSubscription)
    const reschedulingSubscription = useSelector((state) => state.subscribers.reschedulingSubscription)
    const successfulPayments = useSelector((state) => state.businessCustomers.successfulPayments)
    const expiredPayments = useSelector((state) => state.businessCustomers.expiredPayments)
    const fetchingExpiredPayments = useSelector((state) => state.businessCustomers.fetchingExpiredPayments)
    const businessCustomerPayments = useSelector((state) => state.paymentTracks.businessCustomerPayments)
    const fetchingBusinessCustomersExternalPayments = useSelector((state) => state.paymentTracks.fetchingBusinessCustomersExternalPayments);
    const businessCustomerSubscriptionAmounts = useSelector((state) => state.businessCustomers.businessCustomerSubscriptionAmounts);

    //Dispatch Actions
    const dispatch = useDispatch();
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
    const getEnterpriseCustomers = useCallback((businessId) => dispatch(BusinessCustomersActions.businessCustomerAllRequest(businessId)), [dispatch]);
    const getBusinessCustomerSubscriptions = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.businessCustomerSubscriptionsRequest(businessCustomerId)), [dispatch]);
    const getBusinessCustomerRecord = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.businessCustomerRecordRequest(businessCustomerId)), [dispatch]);
    const getRecentCustomerPayments = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerPaymentsRequest(businessCustomerId, size)), [dispatch]);
    const getRecentMissedCustomerPayments = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerMissedPaymentsRequest(businessCustomerId, size)), [dispatch]);
    const getRecentSubscriptionActivity = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerSubscriptionActivityRequest(businessCustomerId, size)), [dispatch]);
    const getCustomerPendingPayments = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.customerPendingPaymentRequest(businessCustomerId)), [dispatch]);
    const getCustomerMissedPayments = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.customerMissedPaymentRequest(businessCustomerId)), [dispatch]);
    const getCustomerReceivedPayments = useCallback((businessCustomerId, options) => dispatch(BusinessCustomersActions.customerReceivedPaymentRequest(businessCustomerId, options)), [dispatch]);
    const cancelSubscriptions = useCallback((subscriptionId, membershipId) => { dispatch(SubscriberActions.cancelSubscriptionRequest(subscriptionId, membershipId)) }, [dispatch])
    const pauseSubscriptions = useCallback((subscriptionId, membershipId) => { dispatch(SubscriberActions.pauseSubscriptionRequest(subscriptionId, membershipId)) }, [dispatch])
    const resumeSubscriptions = useCallback((id, startFrom, membershipId) => { dispatch(SubscriberActions.resumeSubscriptionRequest(id, startFrom, membershipId)) }, [dispatch])
    const rescheduleSubscriptions = useCallback((subscriberId, startFrom, membershipId) => { dispatch(SubscriberActions.rescheduleSubscriptionRequest(subscriberId, startFrom, membershipId)) }, [dispatch])
    const getSuccessfulPayments = useCallback((bcId, options) => { dispatch(BusinessCustomersActions.successfulPaymentsRequest(bcId, options)) }, [dispatch])
    const getExpiredPayments = useCallback((bcId, options) => { dispatch(BusinessCustomersActions.expiredPaymentsRequest(bcId, options)) }, [dispatch])
    const getBusinessCustomerExternalPayments = useCallback((businessId, bcId, options) => { dispatch(PaymentTrackActions.businessCustomerExternalPaymentsRequest(businessId, bcId, options)) }, [dispatch]);
    const getBusinessCustomerSubscriptionAmounts = useCallback((bcId) => { dispatch(BusinessCustomersActions.businessCustomerSubscriptionAmountsRequest(bcId)) }, [dispatch])

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getEnterpriseCustomers(defMembership.business.id)
            getBusinessCustomerSubscriptions(customerId)
            getBusinessCustomerRecord(customerId)
            getRecentCustomerPayments(customerId, "3")
            getRecentMissedCustomerPayments(customerId, "3")
            getRecentSubscriptionActivity(customerId, "3")
            getCustomerPendingPayments(customerId)
            getCustomerMissedPayments(customerId)
            getCustomerReceivedPayments(customerId, { sort: state.sort, size: state.size })
            getSuccessfulPayments(customerId, { sort: state.colSort, size: state.size })
            getExpiredPayments(customerId, { sort: state.expSort, size: state.size })
            getBusinessCustomerExternalPayments(defMembership.business.id, customerId, { sort: state.extSort, size: state.size });
            getBusinessCustomerSubscriptionAmounts(customerId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (businessCustomerSubscriptions) {
            setState((state) => {
                return {
                    ...state,
                    businessCustomerSubscriptions,
                    loading: false,
                }
            })
        }
        if (businessCustomerRecord) {
            setState((state) => {
                return {
                    ...state,
                    customerRecord: businessCustomerRecord
                }
            })
        }
        if (recentPayments) {
            setState((state) => {
                return { ...state, recentPaymentsObject: recentPayments }
            })
        }
        if (recentMissedPayments) {
            setState((state) => { return { ...state, recentMissedPaymentsObject: recentMissedPayments } })
        }
        if (recentSubscriptionActivity) {
            setState((state) => { return { ...state, recentSubscriptionActivityObject: recentSubscriptionActivity } })
        }
        if (customerPendingPayments) {
            setState((state) => { return { ...state, totalPendingAmount: customerPendingPayments } })
        }
        if (customerMissedPayments) {
            setState((state) => { return { ...state, totalMissedAmount: customerMissedPayments } })
        }
        if (customerReceivedPayments) {
            setState((state) => { return { ...state, customerSummary: customerReceivedPayments } })
        }
        if (successfulPayments) {
            setState((state) => { return { ...state, successfulPaymentsObject: successfulPayments } })
        }
        if (expiredPayments) {
            setState((state) => { return { ...state, expiredPaymentsObject: expiredPayments } })
        }
        if (businessCustomerPayments) {
            setState((state) => { return { ...state, customerExternalPayments: businessCustomerPayments.content } })
        }
        if (businessCustomerSubscriptionAmounts) {
            setState((state) => {
                return { ...state, businessCustomerSubscriptionAmounts }
            })
        }
    }, [businessCustomerSubscriptions, businessCustomerRecord, recentPayments, customerReceivedPayments, recentMissedPayments,
        customerPendingPayments, customerMissedPayments, recentSubscriptionActivity,
        successfulPayments, expiredPayments, businessCustomerPayments, state.customerExternalPayments, businessCustomerSubscriptionAmounts
    ])

    useEffect(() => {
        if (!cancellingSubscription && cancelledSubscription && state.cancelled) {
            setState((state) => { return { ...state, cancelled: false, response: cancelledSubscription } })
            cancelSuccess()
            onRefresh()
        }
        if (!pausingSubscription && pausedSubscription && state.paused) {
            setState((state) => { return { ...state, paused: false } })
            pauseSuccess()
            onRefresh()
        }
        if (!resumingSubscription && state.key && state.resumed) {
            setState((state) => { return { ...state, resumed: false, key: false } })
            resumeSuccess()
            onRefresh()
        }
        if (!reschedulingSubscription && state.key && state.rescheduled) {
            setConfirmReschedule(false)
            setState((state) => { return { ...state, rescheduled: false, key: false } })
            rescheduleSuccess()
            onRefresh(true)
        }
        if (!reschedulingSubscription && state.key && state.rescheduled) {
            setConfirmReschedule(false)
            setState((state) => { return { ...state, rescheduled: false, key: false } })
            rescheduleSuccess()
            onRefresh(true)
        }
        if (state.tabChange) {
            setActiveTab(tabs[activeTab].index)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancellingSubscription, state.cancelled, pausingSubscription, pausedSubscription, state.paused, state.tabChange,
        resumingSubscription, resumedSubscription, state.resumed, state.key, state.rescheduled, rescheduledSubscription,])

    useEffect(() => {
        switch (activeTab) {
            case 1:
                getSuccessfulPayments(customerId, { sort: state.colSort, size: state.size });
                break;
            case 2:
                getExpiredPayments(customerId, { sort: state.expSort, size: state.size });
                break;
            case 3:
                getBusinessCustomerExternalPayments(defMembership.business.id, customerId, { sort: state.extSort, size: state.size });
                break;
            default:
                getBusinessCustomerSubscriptions(customerId, { page: state.page, sort: state.sort, size: state.size });
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    if (fetchingCustomerRecord) {
        return <Loading />
    }

    // toast for when a subscription is cancelled successfully
    const cancelSuccess = () => {
        cancelToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.cancelled'), life: 3000 })
    }

    // toast for when a subscription is paused successfully
    const pauseSuccess = () => {
        pauseToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.paused'), life: 3000 })
    }

    // toast for when a subscription is resumed successfully
    const resumeSuccess = () => {
        resumeToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.resumed'), life: 3000 })
    }

    const rescheduleSuccess = () => {
        rescheduleToast.current.show({ severity: 'success', summary: t('subscriber.success'), detail: t('subscriber.rescheduled'), life: 3000 })
    }

    const onRefresh = () => {
        window.location.reload(true);
    }

    const paymentBodyTemplate = (rowData) => {
        if (rowData) {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                {
                    rowData ?
                        <div>
                            <span className="successCount_span">
                                {rowData.subscriber.successCount !== null ? rowData.subscriber.successCount : "0"}
                            </span>
                            <span className="num_payments">{t("common.payments")}</span>
                        </div> : ""
                }
                <div>
                    <span className='business-customer-amnt-text'>
                        {defMembership.business.country.currency}{" "}
                        {rowData?.collectedAmount !== null ? rowData.collectedAmount?.toFixed(2) : "0.00"}
                        <span className="cus_payments">{" "}  {t("business_customers.paid")}</span>
                    </span>
                </div>
                <div>
                    <span className='tier-customer-amnt-text'>
                        {defMembership.business.country.currency} {rowData.redeemable !== null ? rowData.redeemable.toFixed(2) : "0.00"} <span className="tier-customer-amnt-text">
                            <span className="cus_revenue">{t("common.revenue")}</span>
                        </span>
                    </span>
                </div>
                <div>{rowData.subscriber.plan.flex ?
                    <div><span className="missed_count_number">{rowData.missedCount}</span> <span className="missed_count">{t("subscriber.missed")}</span></div> :
                    <span className='business-customer-missed-amnt-text'>
                        {defMembership.business.country.currency} {rowData.missedAmount?.toFixed(2)} <span className="cus_missed">
                            {t("business_customers.missed")}
                        </span>
                    </span>}
                </div>
                {
                    rowData.status === "ACTIVE" || rowData.status === "PAUSED" ?
                        <div >
                            <span className="cus_next_payment"> {t("subscriber.next_payment")}</span>
                            <span className="cus_time">{formatTime(rowData.subscriber?.nextBillingDate)}</span>
                        </div> : null
                }
            </div>
        }
    }

    const statusBodyTemplate = (rowData) => {
        if (rowData.status === "ACTIVE") {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                {<div className="status-date-content" style={{ height: "115px" }}>
                    <span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content`}>{rowData.status}
                    </span>
                </div>
                }
            </div>
        } else if (rowData.status === "CANCELLED") {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                <div className="status-date-content">
                    <span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content`}>{rowData.status}</span>
                </div>
                {
                    rowData.cancelledOn !== null ?
                        <span className="status-date">
                            {formatTime(rowData.subscriber?.cancelledOn)}
                        </span> : ""
                }
            </div>
        } else if (rowData.status === "PAUSED") {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                <div className="status-date-content">
                    <span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content`}>{rowData.status}</span>
                </div>
                {
                    rowData.pausedOn !== null ?
                        <span className="status-date">
                            {formatTime(rowData.subscriber?.pausedOn)}
                        </span> : ""
                }
            </div>
        } else if (rowData.status === "COMPLETE") {
            return <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                <div className="status-date-content">
                    <span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content`}>{rowData.status}</span>
                </div>
                {
                    rowData.completedOn !== null ?
                        <span className="status-date">
                            {formatTime(rowData.subscriber?.completedOn)}
                        </span> : ""
                }
            </div>
        } else {
            return ""
        }
    }

    const subscriptionBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                    {
                        rowData.subscriber.subscriberReference !== null ?
                            <p className="reference-text-customers detail-navigation" id="planName"
                                onClick={() => {
                                    if (rowData.id)
                                        routeChange(`/subscribers/subscriber/subscription/${rowData.id}`, rowData);
                                }}
                            >
                                {rowData.subscriber.subscriberReference}</p>
                            : ""
                    }
                    {
                        rowData.subscriber.plan.name ?
                            <span className="detail-navigation tier-name-content"
                                onClick={() => { history.push({ pathname: "/products/plan/detail/" + rowData.planId }) }}
                            >
                                {rowData.subscriber.plan.group.name !== null ? rowData.subscriber.plan.name : ""}
                            </span> : null
                    }
                    {
                        rowData.subscriber?.plan?.billingPeriod ?
                            <span className="plan-group-text">
                                {rowData.subscriber.plan.billingPeriod !== null ? t(`product.${rowData.subscriber.plan.billingPeriod.toLowerCase()}`) : ""}
                            </span> : null
                    }
                    {
                        rowData.subscriber.plan.groupName &&
                        <span className="plan-text detail-navigation hov"
                            onClick={() => {
                                history.push({
                                    pathname: "/products/detail/" + rowData.groupId
                                })
                            }}>{rowData.subscriber.plan.groupName}
                        </span>
                    }
                    {rowData.subscriber.plan.setup && <div className="setup_fee_main_div setup_pt">
                        <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
                        <span className="setup_fee_value"> {defMembership.business.currency}{" "}{rowData.subscriber.plan.setup?.toFixed(2)}</span>
                    </div>}
                </div >
            </div >
        }
    }

    const amountTemplate = (rowData) => {
        if (rowData.subscriber.plan?.policy === "SCHEDULE") {
            return "--"
        } else if (rowData.subscriber.plan?.policy === "SUBSCRIPTION") {
            return <div>
                {
                    rowData.subscriber.cost !== null ?
                        <span>{`${defMembership.business.country.currency} ${rowData.subscriber.cost?.toFixed(2)}`}</span> : "--"
                }
            </div>
        } else if (rowData.subscriber.plan?.policy === "TIER" && rowData.subscriber.plan.flex === false) {
            return <div>
                {
                    rowData.subscriber.plan.cost !== null ?
                        <span>{`${defMembership.business.country.currency} ${rowData.subscriber.plan.cost?.toFixed(2)}`}</span> : "--"
                }
            </div>
        } else if (rowData.subscriber.plan.flex === true) {
            return t("subscriber.flex_true")
        } else if (rowData.subscriber.plan.cost === null) {
            return '--'
        } else {
            return "--"
        }
    }

    const actionBodyTemplate = (rowData) => {
        if (rowData) {
            return <div className="actionBodyTemplate_div">
                {rowData.status &&
                    <div>
                        <Button
                            icon={"pi pi-info-circle"}
                            className="p-button action-button"
                            tooltip={t("product.details")}
                            onClick={() => {
                                if (rowData.id)
                                    routeChange(`/subscribers/subscriber/subscription/${rowData.id}`, rowData);
                            }}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}>
                        </Button>
                    </div>
                }
                {
                    (rowData.status === "ACTIVE") && (defMembership?.role !== "VIEWER") &&
                    <div>
                        <Button
                            icon={rowData.status === "ACTIVE" && "pi pi-calendar"}
                            className="p-button action-button"
                            tooltip={t('subscriber.reschedule')}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}
                            onClick={() => {
                                setConfirmReschedule(true)
                                setState((state) => { return { ...state, subscriptionId: rowData.id } })
                            }}
                        />
                    </div>
                }
                {
                    (rowData.status === "ACTIVE") && (defMembership?.role !== "VIEWER") &&
                    <div>
                        <Button
                            icon={rowData.status === "ACTIVE" && "pi pi-pause"}
                            className="p-button action-button"
                            tooltip={t("subscriber.pause_label")}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}
                            onClick={() => {
                                setConfirmPause(true)
                                setState((state) => { return { ...state, subscriptionId: rowData.id } })
                            }}
                        />
                    </div>
                }
                {
                    (rowData.status === "PAUSED") && (defMembership?.role !== "VIEWER") &&
                    <div>
                        <Button
                            icon={rowData.status === "PAUSED" && "pi pi-refresh"}
                            className="p-button action-button"
                            tooltip={t("subscriber.resume_label")}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}
                            onClick={() => {
                                setConfirmResume(true)
                                setState((state) => { return { ...state, subscriptionId: rowData.id } })
                            }}
                        />
                    </div>
                }
                <div>
                    {(rowData.status === "ACTIVE" || rowData.status === "PAUSED") && (defMembership?.role !== "VIEWER") &&
                        <Button icon={"pi pi-times-circle"}
                            className="p-button action-button"
                            tooltip={t("subscriber.cancel_label")}
                            tooltipOptions={{ position: "bottom", mouseTrack: true, mouseTrackTop: 15, }}
                            onClick={() => {
                                setConfirmCancel(true)
                                setState((state) => { return { ...state, subscriptionId: rowData.id } })
                            }}
                        />}
                </div>
            </div >
        }
    }

    const markActionTemplate = (rowData) => {
        if (rowData) {
            return (rowData.subscription?.status === "ACTIVE" ? < Button
                style={{ color: (defMembership.role !== "VIEWER") ? "#008dd5" : '#6c757d' }}
                label={t("subscriber.paid")}
                className="p-button-warning p-button-rounded p-button-text mr-2 customerAction"
                onClick={() => {
                    if (defMembership.role !== "VIEWER") {
                        setVisible(true)
                        setState((state) => ({
                            ...state,
                            id: rowData.id,
                            flex: rowData.subscription.plan?.flex,
                            scheduledFor: rowData.scheduledFor
                        }));
                    }
                }}
            /> : "")
        }
    }

    const dateFormatterBody = (rowData, e) => <p className='tier-date'>{rowData[e.field] ? formatTime(rowData[e.field]) : "--"}</p>
    const statusTemplate = (rowData, e) => {
        return rowData[e.field] && <span className={`product-badge status-${(rowData[e.field] ? rowData[e.field].toLowerCase() : '')}`}>{rowData[e.field]}</span>;
    }
    const amount_Template = (tier, e) => tier[e.field] ? `${defMembership.business.country.currency} ${tier[e.field]?.toFixed(2)}` : "--"

    const paymentIdTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <span>
                    {rowData.latestDlr?.id}
                </span>
            </div>
        }
    }
    const occurredOnTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <span className='tier-date'>
                    {moment(rowData.externallyReceivedOn).format('DD MMM YYYY')}
                </span>
            </div>
        }
    }
    const costTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div>
                    {
                        rowData.subscription.plan?.flex === true ?
                            <div>
                                <span className="flexible_amount_customers">{t("products.flexible_amount")}</span>
                                {rowData.extReceivedAmount ? <span className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.extReceivedAmount?.toFixed(2)}</span>
                                    : "--"}
                            </div>
                            :
                            <p className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.cost?.toFixed(2)}</p>
                    }
                </div>
            </div>
        }
    }

    const notesTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {
                    rowData.extReceivedNotes ?
                        <div>
                            <p> {rowData.extReceivedNotes}</p>
                        </div> : "--"
                }
            </div>
        }
    }

    const paymentSubscriptionBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                    {
                        rowData.subscription.subscriberReference !== null ?
                            <p className="reference-text detail-navigation" id="planName"
                                onClick={() => {
                                    if (rowData.subscription.id)
                                        routeChange(`/subscribers/subscriber/subscription/${rowData.subscription.id}`, rowData);
                                }}
                            >
                                {rowData.subscription.subscriberReference}</p>
                            : ""
                    }
                    {
                        rowData.subscription.plan?.name ?
                            <span className="detail-navigation tier-name-content"
                                onClick={() => { history.push({ pathname: "/products/plan/detail/" + rowData.subscription.plan.id }) }}
                            >
                                {rowData.subscription.plan.name !== null ? rowData.subscription.plan.name : ""}
                            </span> : null
                    }
                    {
                        rowData.subscription?.plan?.billingPeriod ?
                            <span className="plan-group-text">
                                {rowData.subscription.plan.billingPeriod !== null ? t(`product.${rowData.subscription.plan.billingPeriod.toLowerCase()}`) : ""}
                            </span> : null
                    }
                    {
                        rowData.subscription.plan?.groupName &&
                        <span className="plan-text detail-navigation hov"
                            onClick={() => {
                                history.push({
                                    pathname: "/products/detail/" + rowData.subscription.plan.groupId
                                })
                            }}
                        >
                            {rowData.subscription.plan.groupName}
                        </span>
                    }
                </div >
            </div >
        }
    }

    const transactionColumn = [
        {
            field: "reference", header: t("products.ref"), body: (data) => <p>{data.latestDlr?.id}</p>, headerClassName: "amount-header"
        },
        {
            header: t("subscriber.info"), body: (subscriber) => {
                return (<div>
                    <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                        {
                            subscriber.subscription.subscriberReference !== null ?
                                <p className="reference-text detail-navigation" id="planName"
                                    onClick={() => {
                                        if (subscriber)
                                            routeChange(`/subscribers/subscriber/subscription/${subscriber.subscription.id}`, subscriber);
                                    }}
                                >
                                    {subscriber.subscription?.subscriberReference}</p>
                                : ""
                        }
                        {
                            subscriber.subscription?.plan?.name ?
                                <span className="detail-navigation tier-name-content"
                                    onClick={() => { history.push({ pathname: "/products/plan/detail/" + subscriber.subscription?.plan.id }) }}
                                >
                                    {subscriber.subscription?.plan?.name !== null ? subscriber.subscription?.plan?.name : ""}
                                </span> : null
                        }
                        {
                            subscriber.subscription?.plan?.billingPeriod ?
                                <span className="plan-group-text">
                                    {subscriber.subscription?.plan?.billingPeriod !== null ? t(`product.${subscriber.subscription?.plan?.billingPeriod.toLowerCase()}`) : ""}
                                </span> : null
                        }
                        {
                            subscriber.subscription?.plan?.groupName &&
                            <span className="plan-text detail-navigation hov"
                                onClick={() => {
                                    history.push({
                                        pathname: "/products/detail/" + subscriber.subscription?.plan.groupId
                                    })
                                }}
                            >
                                {subscriber.subscription?.plan?.groupName}
                            </span>
                        }
                    </div >
                </div >)
            },
            headerClassName: "subsTemplate-header", bodyClassName: "status"
        },
        { field: "cost", header: t("amount"), body: amount_Template, headerClassName: "amount-header" },
        { field: "scheduledFor", body: dateFormatterBody, header: t("products.scheduledDate"), headerClassName: "amount-header" },
        { field: "status", body: statusTemplate, header: t("products.scheduleStatus"), headerClassName: "amount-header" },
        { header: t("products.subscriptionStatus"), body: (subscriber) => <span className={`product-badge status-${(subscriber.subscription?.status ? subscriber.subscription?.status.toLowerCase() : '')}`}>{subscriber.subscription?.status}</span>, headerClassName: "amount-header" },
        { body: markActionTemplate }
    ]

    const tabs = [{
        label: "subscriber.title", columns: [
            { field: 'status', body: statusBodyTemplate, header: t("business_customers.status"), headerClassName: "amount-header", bodyClassName: "status-body" },
            { field: 'subscriber', body: subscriptionBodyTemplate, header: t("subscriber.info"), headerClassName: "subsTemplate-header", bodyClassName: "status" },
            { field: 'subscriber.cost', body: amountTemplate, header: t("dashboard.amount"), headerClassName: "amount-header", bodyClassName: "status" },
            { field: "collectedAmount", body: paymentBodyTemplate, header: t("business_customers.payment"), headerClassName: "subsTemplate-header" },
            { body: actionBodyTemplate, headerClassName: "subsTemplate-header" },
        ],
        value: state.customerSummary, count: state.customerSummary.length, paginator: true, sort: "status,desc", index: 0
    },
    {
        label: "dashboard.collected_payments", columns: [
            {
                field: "customer.reference", header: t("products.ref"), body: (tier) => <p className="link"
                    onClick={() => history.push("/payments/detail/" + tier.id)}>{tier.reference}</p>, headerClassName: "amount-header"
            },
            {
                header: t("subscriber.id"), body: (subscriber) => {
                    return (<div>
                        <div className="subscriptionBodyTemplate subscriptionBodyTemplate_customers">
                            {
                                subscriber.subscriber?.subscriberReference !== null ?
                                    <p className="reference-text detail-navigation" id="planName"
                                        onClick={() => {
                                            if (subscriber.id)
                                                routeChange(`/subscribers/subscriber/subscription/${subscriber.subscriber.id}`, subscriber);
                                        }}
                                    >
                                        {subscriber?.subscriber.subscriberReference}</p>
                                    : ""
                            }
                            {
                                subscriber.plan?.name ?
                                    <span className="detail-navigation tier-name-content"
                                        onClick={() => { history.push({ pathname: "/products/plan/detail/" + subscriber.plan.id }) }}
                                    >
                                        {subscriber.plan?.name !== null ? subscriber.plan?.name : ""}
                                    </span> : null
                            }
                            {
                                subscriber?.plan?.billingPeriod ?
                                    <span className="plan-group-text">
                                        {subscriber.plan?.billingPeriod !== null ? t(`product.${subscriber.plan?.billingPeriod.toLowerCase()}`) : ""}
                                    </span> : null
                            }
                            {
                                subscriber.plan?.groupName &&
                                <span className="plan-text detail-navigation hov"
                                    onClick={() => {
                                        history.push({
                                            pathname: "/products/detail/" + subscriber.plan.groupId
                                        })
                                    }}
                                >
                                    {subscriber.plan?.groupName}
                                </span>
                            }
                        </div >
                    </div >)
                }, headerClassName: "subsTemplate-header", bodyClassName: "status"
            },
            { field: "receivedAt", body: dateFormatterBody, header: t("subscriber.occurred_on"), headerClassName: "amount-header" },
            { field: "amount", header: t("business_customers.paid"), body: amount_Template, headerClassName: "amount-header" },
            { field: "fees", header: t("subscriber.service_fee"), body: amount_Template, headerClassName: "amount-header" },
            { field: "redeemable", header: t("common.revenue"), body: amount_Template, headerClassName: "amount-header" },
        ],
        value: state.successfulPaymentsObject.content, count: state.successfulPaymentsObject.content?.length, paginator: true, sort: "receivedAt,desc", index: 1
    },
    {
        label: t('subscriber.failed_transaction'),
        value: state.expiredPaymentsObject.content,
        columns: transactionColumn, count: state.expiredPaymentsObject.content?.length, paginator: true, sort: "scheduledFor,desc",
        index: 2
    },
    {
        label: "transaction.external", columns: [
            { field: 'id', body: paymentIdTemplate, header: t("products.ref"), headerClassName: "amount-header" },
            { field: 'subscriber', body: paymentSubscriptionBodyTemplate, header: t("subscriber.info"), headerClassName: "subsTemplate-header" },
            { field: 'extReceivedOn', body: occurredOnTemplate, header: t("subscriber.occurred_on"), headerClassName: "amount-header" },
            { field: 'cost', body: costTemplate, header: t("dashboard.amount"), headerClassName: "amount-header" },
            { body: notesTemplate, header: t("subscriber.notes"), headerClassName: "amount-header" },
        ],
        value: state.customerExternalPayments, count: state.customerExternalPayments.length, paginator: true, index: 3
    }
    ]


    const receivedPayments = state.businessCustomerSubscriptions.map((receivedPayments) => {
        return (receivedPayments.status === "ACTIVE" && receivedPayments.successCount)
    })

    const activeSubscriptions = state.businessCustomerSubscriptions.map((activeSubscriptions) => {
        return (activeSubscriptions.status === "ACTIVE" ? 1 : 0)
    })

    const totalActiveSubscriptions = activeSubscriptions.reduce((a, b) => a + b, 0);

    const totalReceivedPayments = receivedPayments.reduce((a, b) => a + b, 0);

    const summaries = [
        {
            data: state.recentSubscriptionActivityObject,
            title: t("subscriber.upcoming"),
            className: "collected", date: "scheduledFor"
        },
        {
            data: state.recentPaymentsObject,
            title: t("subscriber.recent_payments"),
            className: "payment", date: "receivedAt"
        },
        {
            data: state.recentMissedPaymentsObject,
            title: t("subscriber.recent_missed_payments"),
            className: "missed"
        }
    ]

    const sideMenu = [
        {
            label: defMembership?.role !== "VIEWER" ? t('business_customers.edit_customer') : null,
            icon: defMembership?.role !== "VIEWER" ? "pi-pencil" : null,
            onclick: () => {
                setDisplayBasic(true)
                setState((state) => {
                    return {
                        ...state,
                        data: state,
                        id: customerId,
                        firstName: state.customerRecord?.firstName,
                        lastName: state.customerRecord?.lastName,
                        customerNum: state.customerRecord?.customerNum,
                        email: state.customerRecord?.email,
                    }
                })
            }
        }, {
            label: t('business_customers.add'),
            icon: "pi-plus-circle",
            onclick: () => {
                setVisibleInvite(true)
            }
        },
    ]

    const closeDisplayBasic = () => {
        setDisplayBasic(false)
    };


    const pauseSubscriptionActions = (
        <div className="cus_pauseSubscriptionActions">
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" id="pause_no_btn"
                onClick={() => { setConfirmPause(false) }}
            />
            <Button label={t('common.yes')} icon="pi pi-check" loading={pausingSubscription}
                id="pause_yes_btn"
                onClick={() => {
                    pauseSubscriptions(state.subscriptionId, defMembership.id);
                    setState((state) => { return { ...state, paused: true } })
                }}
                autoFocus />
        </div>
    );

    const cancelSubscriptionActions = (
        <div className="cus_cancelSubscriptionActions">
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" id="cancelSubs_no_btn"
                onClick={() => { setConfirmCancel(false) }}
            />
            <Button label={t('common.yes')} icon="pi pi-check" loading={cancellingSubscription}
                id="cancelSubs_yes_btn"
                onClick={() => {
                    cancelSubscriptions(state.subscriptionId, defMembership.id);
                    setState((state) => { return { ...state, cancelled: true } })
                }}
                autoFocus />
        </div>
    );

    const newDate = moment(date).format('YYYY-MM-DD')

    const resumeSubscriptionActions = (
        <div className="cus_resumeSubscriptionActions">
            <Button label={t('common.no')} icon="pi pi-times" className="p-button-text"
                id="resume_no_btn"
                onClick={() => { setConfirmResume(false) }}
            />
            <Button label={t('common.yes')} icon="pi pi-check" loading={resumingSubscription} disabled={!date}
                id="resumeSubs_yes_btn"
                onClick={() => {
                    resumeSubscriptions(state.subscriptionId, newDate, defMembership.id)
                    setState((state) => { return { ...state, resumed: true, key: true } })
                }}
                autoFocus />
        </div>
    );

    const rescheduleSubscriptionActions = (
        <div className="subs-actions-footer">
            <Button label={t('subscriber.cancel')} icon="pi pi-times" className="p-button-text"
                id="reschedule_no_btn"
                onClick={() => { setConfirmReschedule(false) }}
            />
            <Button label={t('subscriber.reschedule')} icon="pi pi-check" loading={reschedulingSubscription} disabled={!date}
                id="reschedule_confirm_btn"
                onClick={() => {
                    rescheduleSubscriptions(state.subscriptionId, newDate, defMembership.id);
                    setState((state) => { return { ...state, rescheduled: true, key: true } })
                }}
                autoFocus />
        </div>
    );

    const toggleInvite = () => {
        setVisibleInvite(false)
    }

    let today = new Date();
    const phoneNumber = state.customerRecord?.phoneNo

    const onChange = (e) => {
        if (e.pageCount <= 1) {
            return;
        } else if (tabs[activeTab].index === 0) {
            getBusinessCustomerSubscriptions(customerId, { page: state.page, sort: state.sort, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 1) {
            getSuccessfulPayments(customerId, { page: e.page, sort: state.colSort, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        } else if (tabs[activeTab].index === 2) {
            getExpiredPayments(customerId, { page: e.page, sort: state.expSort, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        } else if (tabs[activeTab].index === 3) {
            getBusinessCustomerExternalPayments(defMembership.business.id, customerId, { page: e.page, sort: state.extSort, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else {
            return;
        }
    }

    const totalNumber = tabs[activeTab].index === 0
        ? state.customerSummary.length
        : tabs[activeTab].index === 1
            ? state.successfulPaymentsObject?.totalElements
            : tabs[activeTab].index === 2
                ? state.expiredPaymentsObject?.totalElements
                : tabs[activeTab].index === 3
                    ? businessCustomerPayments?.totalElements
                    : null


    const loadingSpinner = fetchingCustomerCollectedPayments || fetchingBusinessCustomersExternalPayments || fetchingExpiredPayments || fetchingCustomerSubscriptions;

    return (
        <div className="grid detail_card">
            <Toast ref={cancelToast} />
            <Toast ref={pauseToast} />
            <Toast ref={resumeToast} />
            < Toast ref={rescheduleToast} />
            <CustomerEdit displayBasic={displayBasic} onHide={closeDisplayBasic} id={state.id} state={state} setState={setState} />
            <InviteSubscriber visible={state.visible} header={t("business_customers.invite")}
                toggleVisible={visibleInvite} onHide={toggleInvite} initialTier={null} phoneNo={phoneNumber} />
            <MarkAsPaid displayDialog={visible} onHide={() => { setVisible(false) }}
                scheduledFor={state.scheduledFor}
                scheduleId={state.id}
                isFlexTrue={state.flex}
                callbackFunctions={() => {
                    getEnterpriseCustomers(defMembership.business.id)
                    getBusinessCustomerSubscriptions(customerId)
                    getBusinessCustomerRecord(customerId)
                    getRecentCustomerPayments(customerId, "3")
                    getRecentMissedCustomerPayments(customerId, "3")
                    getRecentSubscriptionActivity(customerId, "3")
                    getCustomerPendingPayments(customerId)
                    getCustomerMissedPayments(customerId)
                    getCustomerReceivedPayments(customerId)
                    getSuccessfulPayments(customerId)
                    getExpiredPayments(customerId)
                    getBusinessCustomerExternalPayments(defMembership.business.id, customerId)
                }}
            />
            <Dialog
                visible={confirmPause}
                style={{ width: "auto" }}
                onHide={() => setConfirmPause(false)}
                header={t("subscriber.confirmation_header")}
                footer={pauseSubscriptionActions}
            >
                {<h6>{t("subscriber.pause_confirmation_message")}</h6>}
            </Dialog>
            <Dialog onHide={() => setConfirmReschedule(false)} visible={confirmReschedule} header={t("subscriber.reschedule_label")}
             footer={rescheduleSubscriptionActions} style={{ width: "23rem" }}>
                {
                    <div className="field  reschedule-date">
                        <Calendar
                            id="startDate"
                            name="startDate"
                            value={date}
                            minDate={today}
                            readOnlyInput
                            onChange={(e) => setDate(e.value)} showIcon
                            placeholder={t("subscriber.rescheduling_date")}
                            className="p-datepicker-header"
                        />
                    </div>
                }
            </Dialog>
            <Dialog
                visible={confirmCancel}
                style={{ width: "auto" }}
                onHide={() => setConfirmCancel(false)}
                header={t("subscriber.confirmation_header")}
                footer={cancelSubscriptionActions}
            >
                {<h6>{t("subscriber.cancel_confirmation_message")}</h6>}
            </Dialog>
            <Dialog onHide={() => setConfirmResume(false)} visible={confirmResume} header={t("subscriber.resume_label")} footer={resumeSubscriptionActions}>
                {
                    <div className="field col-12 md:col-4 " style={{ width: "18rem" }}>
                        <Calendar
                            id="startDate"
                            name="startDate"
                            value={date}
                            minDate={today}
                            readOnlyInput
                            onChange={(e) => setDate(e.value)} showIcon
                            placeholder={t("subscriber.resumption_date")}
                            className="p-datepicker-header"
                        />
                    </div>
                }
            </Dialog>

            <Card className="customerCard ml-0 mb-0 infoCard">
                <div className="grid">
                    <div className="col-6 md:col-6 lg:col-8 pl-0 align-self-center  pb-0">
                        <span className="label1">{t("business_customers.customer_id")}{": "}</span>
                        <span className="value1">
                            {state.customerRecord?.reference}
                        </span>
                    </div>
                    <div className="col-6 md:col-6 lg:col-4 pb-0">
                        <p className='label1'>
                            {t("business_customers.subs_no")}
                            <span className="customerCard-count">{totalActiveSubscriptions} <span className="subtext1"> {t("business_customers.sub")}</span> </span>

                        </p>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6 md:col-6 lg:col-8 pl-0 align-self-center pb-0">
                        <span className="label1">{t("business_customers.customer_ref")}{": "}</span>
                        <span className="value1">{state.customerRecord?.customerNum}</span>
                    </div>
                    <div className="col-6 md:col-6 lg:col-4 pb-0">
                        <p className='label1'>
                            {t("business_customers.payments_no")}
                            <span className="customerCard-count">
                                {totalReceivedPayments}<span className="subtext1"> {t("business_customers.payments")}</span>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="grid gab">
                    <div className="col-6 md:col-6 lg:col-8  pl-0 pb-0">
                        <p className="value1">{state.customerRecord?.name}</p>
                    </div>
                    <div className="col-6 md:col-6 lg:col-4 pb-0">
                        <p className="customerCard-amount label3 green">
                            {defMembership.business.country.currency}{" "}
                            {state.businessCustomerSubscriptionAmounts?.collectedAmount.toFixed(2)}
                            <span className="customerCard-amnt-text"> {t("business_customers.paid")}</span>
                        </p>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6 md:col-6 lg:col-8  pl-0 pb-0">
                        <p className="customerCard-subs-num">
                            {state.customerRecord?.phoneNo}
                        </p>
                    </div>
                    <div className="col-6 md:col-6 lg:col-4 pb-0">
                        <p className="customerCard-amount label3 blue">
                            {defMembership.business.country.currency}{" "}
                            {state.businessCustomerSubscriptionAmounts?.redeemable.toFixed(2)}
                            <span className="customerCard-amnt-text"> {t("business_customers.revenue")}</span>
                        </p>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-6 md:col-6 lg:col-8  pl-0 pb-0">
                        <p className="customerCard-subsText">
                            {state.customerRecord?.email}
                        </p>
                    </div>
                    <div className="col-6 md:col-6 lg:col-4 pb-0">
                        <p className="customerCard-amount label3 red">
                            {defMembership.business.country.currency}{" "}
                            {state.businessCustomerSubscriptionAmounts?.missedAmount.toFixed(2)}
                            <span className="customerCard-amnt-text"> {t("business_customers.missed")}</span>
                        </p>
                    </div>
                </div>
            </Card>

            <div id="side-menu">
                {sideMenu.map(({ label, icon, onclick }, i) => (
                    <div className="col-12" key={i}>
                        <span onClick={onclick}>
                            <i className={"pi " + icon} />
                            <span> {label}</span>
                        </span>
                        {defMembership?.role !== "VIEWER" ? <hr/> : null}
                    </div>))
                }
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ width: "auto" }}>
                    <h6 className="title-tile ml-0">{t("products.summary")}</h6>
                    <div className="summaryCards flex">
                        {summaries.map(({ data, title, className, date }, i) =>
                            <div className="card" key={i}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span className="title-content">{title}</span>
                                </div>
                                <div className="activities-info">
                                    {data.map((payment, i) => {
                                        return (
                                            <div className="card-info" key={i}>
                                                <span className={"card-info-" + className}>
                                                    {moment(payment?.[date] || payment.latestDlr?.dispatchedOn).format("ll")}
                                                    {
                                                        !!payment.subscription?.plan?.flex ?
                                                            <span className='card-info-amount ml-3'>{t("business_customers.flexAmount")}</span>
                                                            :
                                                            <span className='card-info-amount ml-3'>
                                                                {defMembership.business.country.currency} {(payment.cost || payment.amount)?.toFixed(2)}
                                                            </span>
                                                    }
                                                </span>
                                                <span className="card-info-name" >
                                                    {payment.subscription?.plan?.name || payment?.plan?.name}
                                                </span>

                                            </div>
                                        )
                                    })}
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>

            <div className="detail_card">
                <CustomDataTable
                    value={tabs[activeTab].value}
                    columns={tabs[activeTab].columns}
                    tabs={tabs.map(({ label, i }) => ({ label, i }))}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    setState={setState}
                    tableClassName="tier_detail_data_table"
                    title="business_customers.table_title"
                    children={null}
                    rows={state.size}
                    loading={loadingSpinner}
                    tab={state.tabChange}
                />
                <Paginator
                    className="customer_detail_pagination"
                    rows={state.size}
                    first={state.first}
                    totalRecords={totalNumber}
                    onPageChange={onChange}
                />
            </div>
        </div >
    )
}
export default BusinessCustomers
