import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import Loading from "../../Components/Loading";
import { Button } from "primereact/button";
import SettlementActions from "../../Redux/transition/settlement/settlement.reducer";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import PaymentTrackActions from "../../Redux/transition/payment-track/payment-track.reducer";
import "../../Styles/pages/products.scss";
import "../../Styles/pages/product.scss"
import "../../Styles/pages/customers.scss"
import { Paginator } from "primereact/paginator";
import MarkAsPaid from "../../Components/MarkAsPaid";
import { Dialog } from "primereact/dialog";
import PlanActions from "../../Redux/transition/plan/plan.reducer";
import { Toast } from "primereact/toast";
import ProductEdit from "./ProductEdit";
import CustomDataTable from "../../Components/CustomDataTable";
import ProductDetailCardInsights from "./ProductDetailCardInsights"

const ProductDetail = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation("common");
    const { id: planId } = useParams();
    const [displayBasic, setDisplayBasic] = useState(false);
    const [archive, setArchive] = useState(false);
    const [tierArchive, setTierArchive] = useState(false);
    const [productInfoVisible, setProductInfoVisible] = useState({})
    const [editProductToast, setEditProductToast] = useState(false)
    const [activeTab, setActiveTab] = useState(0)

    const toast = useRef(null);

    const [state, setState] = useState({
        planGroup: {},
        layout: "list",
        sort: "plan.name,desc",
        count: null,
        size: 15,
        first: 0,
        page: 0,
        loading: true,
        withdrawals: [],
        performance: {},
        tiers: [],
        customers: [],
        paid: [],
        missed: [],
        pending: [],
        paidCount: 0,
        pendingCount: 0,
        missedCount: 0,
        visibleCreate: false,
        marking: true,
        id: null,
        planExternalPayments: [],
        isAmountTrue: null,
        productExternalPayments: [],
        productExternalPaymentsCount: 0,
        extOccurredOn: "externallyReceivedOn,desc",
        scheduledFor: "scheduledFor,desc",
        occurredOn: "receivedAt,desc",
    });

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const {
        fetchingPlanGroup,
        planGroup,
        tiers,
        fetchingTiers,
        missed,
        fetchingMissed,
        missedCount,
        fetchingPaymentMissedCount,
        pending,
        fetchingPaymentPending,
        pendingCount,
        fetchingPaymentPendingCount,
        performance,
        fetchingPerformance,
        fetchingPlansSummary,
        fetchingCustomersSummary,
        customersSummary,
        plansSummary,
    } = useSelector((state) => state.planGroups);

    const { creating } = useSelector((state) => state.plans);
    const { sourcePlanGroups, fetchingSourcePlanGroups } = useSelector((state) => state.settlements);
    const { paymentTrackGroup, fetchingGroup, paymentTrackGroupCount, fetchingGroupCount, planPayments, fetchingPlanGroupsExternalPayments } = useSelector((state) => state.paymentTracks);
    const archiving = useSelector((state) => state.planGroups.archiving);
    const successArchiving = useSelector((state) => state.planGroups.archiveSuccess);
    const archivingPlan = useSelector((state) => state.plans.archiving);
    const archivingPlanSuccess = useSelector((state) => state.plans.archiveSuccess);
    const archivingPlanError = useSelector((state) => state.plans.errorArchiving);
    const errorArchiving = useSelector((state) => state.planGroups.errorArchiving);
    const planGroupExternalPayments = useSelector((state) => state.paymentTracks.groupPayments)

    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);
    const getPlanGroup = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupRequest(planId, options)), [dispatch]);
    const getPlanGroupPerformance = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupPerformanceRequest(planId, options)), [dispatch]);
    const getPlanGroupPaymentMissed = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupPaymentMissedRequest(planId, options)), [dispatch]);
    const getPlanGroupPaymentMissedCount = useCallback((planId) => dispatch(PlanGroupActions.planGroupPaymentMissedCountRequest(planId)), [dispatch]);
    const getPlanGroupPaymentPending = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupPaymentPendingRequest(planId, options)), [dispatch]);
    const getPlanGroupPaymentPendingCount = useCallback((planId) => dispatch(PlanGroupActions.planGroupPaymentPendingCountRequest(planId)), [dispatch]);
    const getTiers = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupTierRequest(planId, options)), [dispatch]);
    const getPlanGroupPlansSummary = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupPlansSummaryRequest(planId, options)), [dispatch]);
    const getPlanGroupCustomersSummary = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupCustomersSummaryRequest(planId, options)), [dispatch]);
    const getSettlementsGroup = useCallback((planId, options) => dispatch(SettlementActions.settlementSourcesGroupRequest(planId, options)), [dispatch]);
    const getPaymentTrackGroup = useCallback((planId, options) => dispatch(PaymentTrackActions.paymentTrackGroupRequest(planId, options)), [dispatch]);
    const getPaymentTrackGroupCount = useCallback((planId, options) => dispatch(PaymentTrackActions.paymentTrackGroupCountRequest(planId, options)), [dispatch]);
    const getPlanExternalPayments = useCallback((businessId, planId, options) => { dispatch(PaymentTrackActions.planGroupExternalPaymentsRequest(businessId, planId, options)) }, [dispatch]);
    const archivePlanGroup = useCallback((planGroupId, username) => { dispatch(PlanGroupActions.planGroupArchiveRequest(planGroupId, username)); }, [dispatch]);
    const archivePlan = useCallback((planId, membershipId) => { dispatch(PlanActions.planArchiveRequest(planId, membershipId)); }, [dispatch]);
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)); }, [dispatch]);

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        getPlanGroup(planId);
        getSettlementsGroup(planId);
        getPlanGroupPaymentMissed(planId, { sort: state.scheduledFor, size: state.size });
        getPlanGroupPaymentMissedCount(planId);
        getPlanGroupPaymentPending(planId, { sort: state.scheduledFor, size: state.size });
        getPlanGroupPaymentPendingCount(planId);
        getPaymentTrackGroup(planId, { sort: state.occurredOn, size: state.size });
        getPaymentTrackGroupCount(planId);
        getPlanGroupPlansSummary(planId);
        getPlanGroupCustomersSummary(planId);
        getPlanExternalPayments(defMembership.business.id, planId, { sort: state.extOccurredOn, size: state.size })
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            defMembership,
            getMembership,
            getPlanGroup,
            getPlanGroupPaymentMissed,
            getPlanGroupPaymentMissedCount,
            getPlanGroupPaymentPending,
            getPlanGroupPaymentPendingCount,
            getPlanGroupPerformance,
            getSettlementsGroup,
            getPaymentTrackGroup,
            getPaymentTrackGroupCount,
            planId,
            getPlanGroupPlansSummary,
            getPlanGroupCustomersSummary,
            getPlanExternalPayments,
        ]);

    const onHidePlan = () => {
        setState((state) => {
            return { ...state, visibleCreate: false };
        });
    };

    useEffect(() => {
        if (!archivingPlan && state.planArchiving && archivingPlanSuccess) {
            showSuccess({
                message: t("product.tierArchive_successful"),
                severity: "success",
            });
            setState((state) => ({ ...state, planArchiving: false }));
        }
        if (archivingPlan && state.planArchiving && archivingPlanError) {
            showError();
            setState((state) => ({ ...state, planArchiving: false }));
        }
        if (!archiving && state.archived && successArchiving) {
            toast.current.show({
                severity: "success",
                summary: t("product.success_summary"),
                detail: t("product.archive_successful"),
                life: 3000,
            });
            setState((state) => ({ ...state, archived: false }));
        }
        if (archiving && state.archived && errorArchiving) {
            showError();
            setState((state) => ({ ...state, archived: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [archiving, successArchiving, errorArchiving, state.archived, state.planArchiving, archivingPlan, archivingPlanSuccess, archivingPlanError,]);


    useEffect(() => {
        if (!creating) {
            getTiers(planId);
            getPlanGroupPerformance(planId);
            onHidePlan();
        }
    }, [creating, getPlanGroupPerformance, getTiers, planId]);

    useEffect(() => {
        if (planGroup) {
            setState((state) => {
                return { ...state, planGroup: planGroup };
            });
        }
        if (performance) {
            setState((state) => {
                return { ...state, performance: performance };
            });
        }
        if (plansSummary) {
            setState((state) => {
                return { ...state, tiers: plansSummary };
            });
        }
        if (customersSummary) {
            setState((state) => {
                return { ...state, customers: customersSummary };
            });
        }
        if (sourcePlanGroups) {
            setState((state) => {
                return { ...state, withdrawals: sourcePlanGroups };
            });
        }
        if (missed) {
            setState((state) => ({ ...state, missed: missed?.content }));
        }
        if (pending) {
            setState((state) => ({ ...state, pending: pending?.content }));
        }
        if (paymentTrackGroup) {
            setState((state) => ({ ...state, paid: paymentTrackGroup }));
        }
        if (paymentTrackGroupCount) {
            setState((state) => ({ ...state, paidCount: paymentTrackGroupCount }));
        }
        if (missedCount) {
            setState((state) => ({ ...state, missedCount: missedCount }));
        }
        if (pendingCount) {
            setState((state) => ({ ...state, pendingCount: pendingCount }));
        }
        if (planPayments) {
            setState((state) => { return { ...state, planExternalPayments: planPayments.content } })
        }
        if (planGroupExternalPayments) {
            setState((state) => { return { ...state, productExternalPayments: planGroupExternalPayments.content, productExternalPaymentsCount: planGroupExternalPayments.totalElements } })
        }
    }, [
        fetchingSourcePlanGroups,
        performance,
        planGroup,
        tiers,
        missed,
        pending,
        missedCount,
        pendingCount,
        paymentTrackGroup,
        paymentTrackGroupCount,
        sourcePlanGroups,
        plansSummary,
        customersSummary,
        planPayments, state.planExternalPayments, planGroupExternalPayments, state.productExternalPayments
    ]);

    if (
        fetchingPlanGroup ||
        fetchingTiers ||
        fetchingPaymentMissedCount ||
        fetchingPaymentPendingCount ||
        fetchingSourcePlanGroups ||
        fetchingPerformance ||
        fetchingSourcePlanGroups ||
        creating ||
        fetchingGroupCount ||
        fetchingPlansSummary ||
        fetchingCustomersSummary
    ) {
        return <Loading />;
    }

    const showSuccess = (data) => {
        toast.current.show({
            severity: "success",
            summary: t("product.success_summary"),
            detail: data.message,
            life: 3000,
        });
    };
    const showError = (data) => {
        toast.current.show({
            severity: "error",
            summary: t("product.error_summary"),
            detail: t("product.archive_error"),
            life: 3000,
        });
    };

    const formatTime = (time) => {
        if (moment(time).isValid()) {
            return moment(time).format("MMMM Do YYYY");
        }
    };

    const moneyTemplate = (tier, e) => {
        const formattedCost = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tier[e.field]);
        return <div style={{ color: "black", fontWeight: "800" }} className={tier[e.field] ? "rev" : "no-rev"}>{tier[e.field] ? `${defMembership.business.country.currency} ${formattedCost}` : "--"}</div>
    };

    const paymentMoneyTemplate = (tier, e) => {
        const formattedCollected = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tier.collected);
        const formattedRevenew = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tier.revenue);
        return <section className="payment-col" >
            <div style={{ display: "flex" }}><div className={tier.collected ? "collected" : "no-revn"}>{tier.collected ? `${defMembership.business.country.currency} ${formattedCollected}` : "--"}</div>
                <div className="coll-title">{tier.collected ? t("product.collected") : null}</div></div>

            <div style={{ display: "flex", width: "10rem", marginTop: "3px" }}><div className={tier.revenue ? "revn" : "no-revn"}>{tier.revenue ? `${defMembership.business.country.currency} ${formattedRevenew}` : "--"}</div>
                <div className="rev-title">{tier.revenue ? t("products.revenue") : null}</div></div>
        </section>
    };

    const statusTemplate = (rowData, e) => {
        const lastModifiedDate = formatTime(rowData.lastModifiedDate);
        const createdOnDate = formatTime(rowData.createdDate)
        return <div className="stat-cont">{rowData[e.field] &&
            (<span className={`product-badge status-${rowData[e.field] ? rowData[e.field].toLowerCase() : ""} status-align`}>{rowData[e.field]}</span>)}
            <div>{rowData?.lastModifiedDate ? <span style={{ textAlign: "end" }}>
                <span className="labelOn">{t("product.modified")}</span>
                <span className="labelOnDate">{lastModifiedDate}</span>
            </span>
                :
                <span className="status-dates">
                    <span className="labelOn">{t("product.created")}</span>
                    <span className="labelOnDate">{createdOnDate}</span>
                </span>}
            </div>
        </div>
    };

    const incidenceTemplate = (rowData) => {
        return <div className="subs-col flex">
            <span className="subs">{rowData.incidence}</span>
            <span className="subs-title">{t("product.subscriptions")}</span>
        </div>
    }

    const durationTemplate = (rowData) => {
        const formatText = (text) => {
            switch (text) {
                case "BIWEEKLY":
                    return <>{t("products.bi-weekly")} </>
                case "DAILY_WITH_WEEKENDS":
                    return <>{t("products.period_DAILY_WITH_WEEKENDS")} </>
                case "DAILY_WITH_SATURDAYS":
                    return <>{t("products.period_DAILY_WITH_SATURDAYS")} </>
                default:
                    return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
            }
        }
        if (rowData) {
            return <div className="duration-content bill-period">{rowData.plan?.billingPeriod ? <span id="planPeriod">{formatText(rowData.plan?.billingPeriod)}</span> : null}</div>;
        }
    };

    const subsTemplate = (rowData, e) => {
        return <div className="subs-template-content">
            <div className="space-start">
                <div className="subscriptionReference plan-nav" style={{ marginBottom: "0px" }}
                    onClick={() => {
                        history.push(`/products/plan/detail/${rowData.planId}`);
                    }}
                >
                    {rowData.planName}
                </div>
            </div>
            <div style={{ textAlign: "start" }}>
                <div className="space"> <span className="cust-name">{rowData.customer.name}</span></div>
                <div><p className="cust-phoneNo">{rowData.customer.phoneNo}</p></div>
            </div>
            {rowData.setupFee ? <div style={{ textAlign: "start" }}>
                <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
                <span className="setup_fee_value">{defMembership.business.currency}{" "}{rowData.setupFee?.toFixed(2)}</span>
            </div> : " "}
        </div>
    }

    const pendingTransactionColumn = [
        {
            header: t("products.payment_schedule"),
            className: "plans-name",
            alignHeader: "left",
            body: (tier) => (
                <div className="schedule-main-content">
                    <div className="space-start">
                        <span style={{ display: "flex", width: "6rem", justifyContent: "center" }} className={`subscription-status status-${tier.scheduleStatus}`}>
                            {tier.scheduleStatus.toUpperCase()}
                        </span>
                    </div>
                    <div style={{ textAlign: "start" }}>
                        <div className="space"> <span className="cust-scheduledFor">{t("subscriber.scheduled_for")}</span></div>
                        <div><p className="product_scheduledFor" style={{ fontWeight: "500" }}>{tier.scheduledFor ? moment(tier.scheduledFor).format('MMMM D, YYYY h:mm A') : "--"}</p></div>
                    </div>
                </div>
            ),
            bodyClassName: "payments-schedule-body"
        },

        { field: "scheduledDate", alignHeader: "left", body: subsTemplate, header: t("products.subscription"), bodyClassName: "schedule-date-body" },

        { field: "amountDue", alignHeader: "left", header: t("amount"), body: moneyTemplate, bodyClassName: "money-template" },
    ];

    const tabs = [
        {
            label: t("products.plans"),
            columns: [
                { field: "plan.status", body: (row, e) => statusTemplate(row.plan, { field: "status" }), alignHeader: "center", header: t("subscriber.status"), headerClass: "stat-header" },
                {
                    field: "name",
                    alignHeader: "left",
                    header: t("products.name/dec"),
                    body: (tier) => (
                        <div className='name-desc'>
                            <p onClick={() => history.push("/products/plan/detail/" + tier.id)} className="plans-name link">
                                {tier.planName}
                            </p>
                            {tier.plan.description && <p style={{ lineHeight: "0rem", paddingTop: "0.7rem" }}>{tier.plan.description}</p>}
                            {tier.plan.setup && <div className="setup_fee_main_div">
                                <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
                                <span className="setup_fee_value">{defMembership.business.currency}{" "}{tier.plan.setup?.toFixed(2)}</span>
                            </div>}
                        </div>
                    ),
                },
                {
                    field: "plan.cost",
                    alignHeader: "left",
                    header: t("amount"),
                    body: (row, e) => (<div>
                        <span className={row.plan.flex ? "no-rev-flex" : "amt-col"}>
                            {row.plan.flex ? t("products.flexible_amount") : moneyTemplate(row.plan, { field: "cost" })}
                        </span>
                    </div>),
                },
                { field: "plan.billingPeriod", alignHeader: "left", body: durationTemplate, header: t("products.frequency") },
                { field: "incidence", alignHeader: "left", header: t("subscriptions"), body: incidenceTemplate },
                { field: "revenue", alignHeader: "left", header: t("products.payments"), body: paymentMoneyTemplate },
            ],
            data: state.tiers,
            count: state.tiers.length,
            index: 0
        },
        {
            label: t("subscriber.upcoming"),
            data: state.pending,
            columns: pendingTransactionColumn,
            count: state.pendingCount,
            index: 3
        },
    ];

    const reject = () => {
        setArchive(false);
    };

    const accept = () => {
        if (tierArchive) {
            setState((state) => ({ ...state, planArchiving: true }));
            archivePlan(state.planGroup.id, defMembership.id);
            setTierArchive(false);
        } else {
            setState((state) => ({ ...state, archived: true }));
            archivePlanGroup(state.planGroup.id, defMembership.user.login);
        }
    };

    const archiveFooter = () => {
        return (
            <div>
                <Button
                    id="footer-btn-cancel"
                    label={t("cancel")}
                    icon="pi pi-times"
                    onClick={reject}
                />
                <Button
                    id="archive-btn"
                    loading={archiving}
                    label={t("share.archive")}
                    icon="pi pi-check"
                    onClick={accept}
                />
            </div>
        );
    };

    const totalNumber = tabs[activeTab].index === 0
        ? state.tiers.length
        : tabs[activeTab].index === 1
            ? state.customers.length
            : tabs[activeTab].index === 2
                ? state.missedCount
                : tabs[activeTab].index === 3
                    ? state.pendingCount
                    : tabs[activeTab].index === 4
                        ? state.paidCount
                        : tabs[activeTab].index === 5
                            ? state.withdrawals.length
                            : tabs[activeTab].index === 6
                                ? state.productExternalPaymentsCount
                                : null


    const paginationCheck = tabs[activeTab].index === 2 || tabs[activeTab].index === 3 || tabs[activeTab].index === 4 || tabs[activeTab].index === 6

    const loadingSpinner = fetchingMissed || fetchingPaymentPending || fetchingPlanGroupsExternalPayments || fetchingGroup

    const onChange = (e) => {
        if (e.pageCount <= 1) {
            return;
        } else if (tabs[activeTab].index === 2) {
            getPlanGroupPaymentMissed(planId, { page: e.page, sort: state.scheduledFor, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 3) {
            getPlanGroupPaymentPending(planId, { page: e.page, sort: state.scheduledFor, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        } else if (tabs[activeTab].index === 4) {
            getPaymentTrackGroup(planId, { page: e.page, sort: state.occurredOn, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        } else if (tabs[activeTab].index === 6) {
            getPlanExternalPayments(defMembership.business.id, planId, { page: e.page, sort: state.extOccurredOn, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else {
            return;
        }
    }

    return (
        <div className="planDetailScreen" >
            <MarkAsPaid displayDialog={displayBasic} onHide={() => { setDisplayBasic(false) }}
                scheduleId={state.id}
                scheduledDate={state.scheduledDate}
                isFlexTrue={state.isAmountTrue}
                callbackFunctions={() => {
                    getPlanGroup(planId);
                    getSettlementsGroup(planId);
                    getPlanGroupPaymentMissed(planId);
                    getPlanGroupPaymentMissedCount(planId);
                    getPlanGroupPaymentPending(planId);
                    getPlanGroupPaymentPendingCount(planId);
                    getPaymentTrackGroup(planId);
                    getPaymentTrackGroupCount(planId);
                    getPlanGroupPlansSummary(planId);
                    getPlanGroupCustomersSummary(planId);
                    getPlanExternalPayments(defMembership.business.id, planId)
                }}
            />

            <Toast
                ref={toast}
                onRemove={(message) => {
                    if (message.severity === "success") {
                        getActivePlanGroups(defMembership.business.id);
                        getPlanGroup(planId);
                        getSettlementsGroup(planId);
                        getPlanGroupPaymentMissed(planId);
                        getPlanGroupPaymentMissedCount(planId);
                        getPlanGroupPaymentPending(planId);
                        getPlanGroupPaymentPendingCount(planId);
                        getPaymentTrackGroup(planId);
                        getPaymentTrackGroupCount(planId);
                        getPlanGroupPlansSummary(planId);
                        getPlanGroupCustomersSummary(planId);
                        getPlanExternalPayments(defMembership.business.id, planId)
                        setArchive(false);
                    }
                }} />
            <Dialog
                header={t("Archive")}
                visible={archive}
                onHide={() => setArchive(false)}
                footer={archiveFooter()}
                className="tier_dialog"
                id="tier_dialog"
                style={{ width: "300px" }}
            >
                <div className="archive-dialog-content">
                    <p style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            className="pi pi-exclamation-circle"
                            style={{
                                color: "#D0021B",
                                fontSize: "40px",
                                fontWeight: "bolder",
                            }}
                        />
                    </p>
                    <div style={{ padding: "10px" }}>
                        <div style={{ marginBottom: "0.5rem" }}>
                            <span>
                                {t("product.archive_question")}{" "}
                                <span style={{ fontWeight: "bold", color: "#000" }}>
                                    {state.rowData?.name}
                                </span>{"?"}{" "}
                            </span>
                        </div>
                        <p className="archive-notice">
                            {t("product.archive_notice")}
                        </p>
                    </div>
                    <hr style={{ border: "1px solid #F2F2F2" }} />
                </div>
            </Dialog>

            <ProductDetailCardInsights  {...{ state, t, defMembership, planGroup, fetchingPerformance, setArchive, setProductInfoVisible }} />

            <h6 className="title-tile products-title-tile ml-0">{t("products.activities")}</h6>
            <CustomDataTable
                value={tabs[activeTab].data}
                columns={tabs[activeTab].columns}
                tabs={tabs.map(({ label, i }) => ({ label, i }))}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setState={setState}
                tableClassName="tier_detail_data_table"
                children={null}
                rows={state.size}
                loading={loadingSpinner}
                tab={state.tabChange}
                paginator={!paginationCheck ? true : false}
            />
            {paginationCheck ?
                <Paginator
                    rows={state.size}
                    first={state.first}
                    totalRecords={totalNumber}
                    onPageChange={onChange}
                /> : ""
            }
            <ProductEdit
                product={productInfoVisible}
                onHide={() => setProductInfoVisible({})}
                setShowProductEdit={setEditProductToast}
                showProductEdit={editProductToast} />
        </div>
    );
};

export default ProductDetail;