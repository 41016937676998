import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../Components/Loading";
import moment from "moment";
import { getLastMonthEnd, getThisWeekEnd } from "../../Service/Moment";
import { TabMenu } from "primereact/tabmenu";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import PaymentScheduleActions from "../../Redux/transition/payment-schedule/payment-schedule.reducer";
import BusinessActions from "../../Redux/actions/business-actions";
import DocumentActions from '../../Redux/transition/document-upload/document-upload.reducer';
import PlanActions from "../../Redux/transition/plan/plan.reducer"
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import "../../Styles/pages/dashboard.scss";
import DashboardCharts from "./chart/DashboardChart";
import ProductCreate from "../Products/ProductCreate";
import SummaryCard from "../Dashboard/SummaryCard"
import { useHistory } from 'react-router-dom';
import OnboardingNotification from "../../Components/OnboardingNotification";

let mount = false;
const Dashboard = () => {
	const history = useHistory();
	const previousUrl = history.location.state;

	// Split the URL by '/' and get the last segment
	const segments = previousUrl?.split('/');
	const lastSegment = segments && segments[segments?.length - 1];

	const { t } = useTranslation("common");
	const [openNotification, setOpenNotification] = useState(false);
	const [configureUrl, setConfigureUrl] = useState("");
	const [onboardingDialog, setOnboardingDialog] = useState(false)

	// redux state from store
	const dailyCharts = useSelector((state) => state.paymentSchedules.dailyCharts)
	const weeklyCharts = useSelector((state) => state.paymentSchedules.weeklyCharts)
	const threeMonthsCharts = useSelector((state) => state.paymentSchedules.threeMonthsCharts)
	const sixMonthsCharts = useSelector((state) => state.paymentSchedules.sixMonthsCharts)
	const twelveMonthsCharts = useSelector((state) => state.paymentSchedules.twelveMonthsCharts)
	const businessSummary = useSelector((state) => state.businesses.businessSummary)
	const weeklySummary = useSelector((state) => state.paymentSchedules.weeklyPaymentSummary)
	const threeMonthsSummary = useSelector((state) => state.paymentSchedules.threeMonthsPaymentSummary)
	const sixMonthsSummary = useSelector((state) => state.paymentSchedules.sixMonthsPaymentSummary)
	const twelveMonthsSummary = useSelector((state) => state.paymentSchedules.twelveMonthsPaymentSummary)
	const paymentScheduleSummary = useSelector((state) => state.paymentSchedules.paymentScheduleSummary)
	const defMembership = useSelector((state) => state.defMembership.defMembership)
	const fetchingDailyCharts = useSelector((state) => state.paymentSchedules.fetchingDailyCharts)
	const fetchingWeeklyCharts = useSelector((state) => state.paymentSchedules.fetchingWeeklyCharts)
	const fetchingThreeMonthsCharts = useSelector((state) => state.paymentSchedules.fetchingThreeMonthsCharts)
	const fetchingSixMonthsCharts = useSelector((state) => state.paymentSchedules.fetchingSixMonthsCharts)
	const fetchingTwelveMonthsCharts = useSelector((state) => state.paymentSchedules.fetchingTwelveMonthsCharts)
	const plans = useSelector((state) => state.plans.plans)
	const fetchingPlans = useSelector((state) => state.plans.fetchingAll)
	const planGroupPerformance = useSelector((state) => state.planGroups.businessPerformance)
	const fetchingPerformance = useSelector((state) => state.planGroups.fetchingPerformance)
	const fetchingBusinessSummary = useSelector((state) => state.businesses.fetchingBusinessSummary)

	// redux dispatch actions
	const dispatch = useDispatch()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getDailyCharts = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.dailyChartsRequest(businessId, startDate, endDate), [dispatch]) })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getWeeklySummary = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.WeeklyPaymentScheduleSummaryRequest(businessId, startDate, endDate), [dispatch]) })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getThreeMonthsSummary = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.threeMonthsPaymentScheduleSummaryRequest(businessId, startDate, endDate), [dispatch]) })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getSixMonthsSummary = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.sixMonthsPaymentScheduleSummaryRequest(businessId, startDate, endDate), [dispatch]) })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getTwelveMonthsSummary = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.twelveMonthsPaymentScheduleSummaryRequest(businessId, startDate, endDate), [dispatch]) })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getWeeklyCharts = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.weeklyChartsRequest(businessId, startDate, endDate), [dispatch]) })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getThreeMonthsCharts = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.threeMonthsChartsRequest(businessId, startDate, endDate), [dispatch]) })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getSixMonthsCharts = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.sixMonthsChartsRequest(businessId, startDate, endDate), [dispatch]) })
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getTwelveMonthsCharts = useCallback((businessId, startDate, endDate) => { dispatch(PaymentScheduleActions.twelveMonthsChartsRequest(businessId, startDate, endDate), [dispatch]) })
	const getBusinessSummary = (businessId) => dispatch(BusinessActions.businessSummaryRequest(businessId))
	const getMembership = () => dispatch(DefMembershipActions.defMembershipRequest())
	const getPaymentScheduleSummary = (businessId, startDate, endDate) => dispatch(PaymentScheduleActions.paymentScheduleSummaryRequest(businessId, startDate, endDate))
	const getPlans = useCallback((businessId) => { dispatch(PlanActions.planAllRequest(businessId)) }, [dispatch])
	const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch]);
	const getPlanBusinessPerformance = useCallback((businessId, options) => { dispatch(PlanGroupActions.planBusinessPerformanceRequest(businessId, options)) }, [dispatch]);
	const getVerificationRecord = useCallback((businessId) => { dispatch(DocumentActions.businessVerificationStatusRequest(businessId)) }, [dispatch]);

	const [activeIndex, setActiveIndex] = useState(1)
	const [dialogClosed, setDialogClosed] = useState(false);
	const [dialog, setDialog] = useState(false);
	const [check, setCheck] = useState(false);
	const [state, setState] = useState({
		lastMonthEnd: getLastMonthEnd(),
		thisWeekEnd: getThisWeekEnd(),
		tabMenuItems: [
			{ id: 0, className: "tab tab2" },
			{ label: "dashboard.daily", id: 1, className: "tab tab1 " },
			{ label: "dashboard.weekly", id: 2, className: "tab next" },
			{ label: "dashboard.3_months", id: 3, className: "tab default-tab" },
			{ label: "dashboard.6_months", id: 4, className: "tab next" },
			{ label: "dashboard.12_months", id: 5, className: "tab edge-tab-end" },
		],
		data: [],
		paymentScheduleSummaryObject: {},
		weeklySummaryObject: {},
		threeMonthsSummaryObject: {},
		sixMonthsSummaryObject: {},
		twelveMonthsSummaryObject: {},
		collectedData: [],
		missedData: [],
		pendingData: [],
		pendingAmountData: [],
		activeData: [],
		cancelledData: [],
		newSubscriptions: [],
		paymentSummaryData: {},
		duration: "",
		monthlyChart: false,
		tierVisible: false,
		duplicate: null,
		subscribers: null,
		businessSummaryObject: {},
		planGroupPerformanceObject: {},
	});

	const today = new Date();
	const priorDate = new Date(new Date().setDate(today.getDate() - 30));
	const priorWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 84);

	const endDate = moment(today).format().split("T").shift()
	const dailyStartDate = moment(priorDate).format().split("T").shift()
	const weeklyStartDate = moment(priorWeek).format().split("T").shift()
	const thisWeekEnd = moment(state.thisWeekEnd).format().split("T").shift();
	const lastMonthEnd = moment(state.lastMonthEnd).format().split("T").shift();


	const calculateFirstDayOfMonth = (months) => {
		const today = new Date();
		const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
		const targetDate = new Date(firstDayOfCurrentMonth.setMonth(firstDayOfCurrentMonth.getMonth() - months));
		const firstDayOfTargetMonth = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
		return moment(firstDayOfTargetMonth).format().split("T").shift()
	};

	const firstDayOfThreeMonthsAgo = calculateFirstDayOfMonth(3);
	const firstDayOfSixMonthsAgo = calculateFirstDayOfMonth(6);
	const firstDayOfTwelveMonthsAgo = calculateFirstDayOfMonth(12);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getDaily = () => {
		getPaymentScheduleSummary(
			defMembership.business.id,
			dailyStartDate,
			endDate,
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getWeekly = () => {
		getWeeklySummary(
			defMembership.business.id,
			weeklyStartDate,
			thisWeekEnd,
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const get3Months = () => {
		getThreeMonthsSummary(
			defMembership.business.id,
			firstDayOfThreeMonthsAgo,
			lastMonthEnd,
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const get6Months = () => {
		getSixMonthsSummary(
			defMembership.business.id,
			firstDayOfSixMonthsAgo,
			lastMonthEnd,
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const get12Months = () => {
		getTwelveMonthsSummary(
			defMembership.business.id,
			firstDayOfTwelveMonthsAgo,
			lastMonthEnd,
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getLast30DaysDaily = () => {
		getDailyCharts(
			defMembership.business.id,
			dailyStartDate,
			endDate,
		)
	}

	const getLast12WeeksWeekly = () => {
		getWeeklyCharts(
			defMembership.business.id,
			weeklyStartDate,
			thisWeekEnd,
		)
	}

	const getLast3MonthsMonthly = () => {
		getThreeMonthsCharts(
			defMembership.business.id,
			firstDayOfThreeMonthsAgo,
			lastMonthEnd,
		)
	}

	const getLast6MonthsMonthly = () => {
		getSixMonthsCharts(
			defMembership.business.id,
			firstDayOfSixMonthsAgo,
			lastMonthEnd,
		)
	}

	const getLast12MonthsMonthly = () => {
		getTwelveMonthsCharts(
			defMembership.business.id,
			firstDayOfThreeMonthsAgo,
			lastMonthEnd,
		)
	}

	useEffect(() => {
		const onboardingDialog = JSON.parse(localStorage.getItem('onboardingDialog'));
		if (onboardingDialog === true) {
			setOnboardingDialog(true);
		}
	}, [onboardingDialog]);

	useEffect(() => {
		if (!defMembership) {
			getMembership();
		}
		if (defMembership && !mount) {
			getPlanBusinessPerformance(defMembership.business.id)
			getDaily();
			getBusinessSummary(defMembership.business.id);
			getLast30DaysDaily();
			getPlans(defMembership.business.id)
			getActivePlanGroups(defMembership.business.id);
			getVerificationRecord(defMembership.business.id)
			mount = true;
		}
		if (lastSegment) {
			setConfigureUrl(lastSegment)
		}
		const dialogClosed = JSON.parse(localStorage.getItem('dialogClosed'));
		setDialogClosed(dialogClosed);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defMembership]);

	useEffect(() => {
		if (businessSummary) {
			setState((state) => ({ ...state, businessSummaryObject: businessSummary }))
		}
		if (planGroupPerformance) {
			setState((state) => ({ ...state, planGroupPerformanceObject: planGroupPerformance }))
		}
	}, [businessSummary, planGroupPerformance, state.businessSummaryObject, state.planGroupPerformanceObject])

	useEffect(() => {
		if (state.subscribers) {
			setState((state) => ({ ...state, allSubscribers: state.subscribers }))
		}
		if (activeIndex === 1 && dailyCharts && paymentScheduleSummary) {
			setState((state) => {
				return {
					...state, paymentSummaryData: paymentScheduleSummary, collectedData: dailyCharts.paidSeries, newSubscriptions: dailyCharts.newSeries,
					missedData: dailyCharts.missedSeries, pendingData: dailyCharts.pendingSeries, activeData: dailyCharts.activeSeries,
					cancelledData: dailyCharts.cancelledSeries, duration: t("dashboard.duration"), pendingAmountData: paymentScheduleSummary.pendingSchedules
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (activeIndex === 2 && weeklyCharts && weeklySummary) {
			setState((state) => {
				return {
					...state, paymentSummaryData: weeklySummary, collectedData: weeklyCharts.paidSeries, newSubscriptions: weeklyCharts.newSeries,
					missedData: weeklyCharts.missedSeries, pendingData: weeklyCharts.pendingSeries, activeData: weeklyCharts.activeSeries,
					cancelledData: weeklyCharts.cancelledSeries, duration: t("dashboard.weekly_duration"), pendingAmountData: weeklySummary.pendingSchedules
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (activeIndex === 3 && threeMonthsCharts && threeMonthsSummary) {
			setState((state) => {
				return {
					...state, paymentSummaryData: threeMonthsSummary, collectedData: threeMonthsCharts.paidSeries, newSubscriptions: threeMonthsCharts.newSeries,
					missedData: threeMonthsCharts.missedSeries, pendingData: threeMonthsCharts.pendingSeries, activeData: threeMonthsCharts.activeSeries,
					cancelledData: threeMonthsCharts.cancelledSeries, duration: t("dashboard.3_months_duration"), monthlyChart: true, pendingAmountData: threeMonthsSummary.pendingSchedules
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (activeIndex === 4 && sixMonthsCharts && sixMonthsSummary) {
			setState((state) => {
				return {
					...state, paymentSummaryData: sixMonthsSummary, collectedData: sixMonthsCharts.paidSeries, newSubscriptions: sixMonthsCharts.newSeries,
					missedData: sixMonthsCharts.missedSeries, pendingData: sixMonthsCharts.pendingSeries, activeData: sixMonthsCharts.activeSeries,
					cancelledData: sixMonthsCharts.cancelledSeries, duration: t("dashboard.6_months_duration"), monthlyChart: true, pendingAmountData: sixMonthsSummary.pendingSchedules
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (activeIndex === 5 && twelveMonthsCharts && twelveMonthsSummary) {
			setState((state) => {
				return {
					...state, paymentSummaryData: twelveMonthsSummary, collectedData: twelveMonthsCharts.paidSeries, newSubscriptions: twelveMonthsCharts.newSeries,
					missedData: twelveMonthsCharts.missedSeries, pendingData: twelveMonthsCharts.pendingSeries, activeData: twelveMonthsCharts.activeSeries,
					cancelledData: twelveMonthsCharts.cancelledSeries, duration: t("dashboard.12_months_duration"), monthlyChart: true, pendingAmountData: twelveMonthsSummary.pendingSchedules
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeIndex, dailyCharts, weeklyCharts, threeMonthsCharts, sixMonthsCharts, twelveMonthsCharts,
		paymentScheduleSummary, weeklySummary, threeMonthsSummary, sixMonthsSummary, twelveMonthsSummary])

	useEffect(() => {
		if (!loading) {
			const timer = setTimeout(() => {
				setCheck(true);
			}, 1000);
			return () => clearTimeout(timer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (plans?.length < 1 && defMembership?.business.verified && check && !dialogClosed) {
			function showDialog(callback) {
				toggleTierDialog()
				callback();
			}
			function setDialogState() {
				setDialog(true)
			}
			// Call functionA and pass functionB as the callback
			showDialog(setDialogState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [plans, check])

	useEffect(() => {
		if (check && dialog && !state.tierVisible) {
			localStorage.setItem('dialogClosed', JSON.stringify(true));
		}
	}, [state.tierVisible, check, dialog]);

	useEffect(() => {
		if (configureUrl === "configure" && onboardingDialog) {
			setOpenNotification(true)
		}
	}, [configureUrl, onboardingDialog])

	const toggleTierDialog = () => { setState({ ...state, tierVisible: !state.tierVisible, duplicate: null }); };

	const summaryData = state.paymentSummaryData

	const pendingMoney = state.pendingAmountData.map((pending) => {
		return (pending.amountDue)
	})

	const totalPending = pendingMoney.reduce((a, b) => a + b, 0);

	const loading = fetchingDailyCharts || fetchingSixMonthsCharts || fetchingThreeMonthsCharts || fetchingTwelveMonthsCharts || fetchingWeeklyCharts || fetchingPlans

	if (!defMembership || fetchingBusinessSummary || fetchingPerformance || fetchingPlans) {
		return <Loading />
	}

	const formatDate = date => {
		return moment(date).format('MMM DD')
	}

	const tabIndex = activeIndex === 3 || activeIndex === 4 || activeIndex === 5

	const newDateSubscribed = state.newSubscriptions.map((date) => {
		return (tabIndex ? date.displayName : formatDate(date.displayName))
	})
	const newIncidenceCount = state.newSubscriptions.map((subscribers) => {
		return (subscribers.incidence)
	})
	const newSubscriptionsData = {
		labels: newDateSubscribed,
		datasets: [
			{
				label: t("dashboard.new_subscriptions"),
				data: newIncidenceCount,
				fill: false,
				borderColor: '#FFB300',
				backgroundColor: state.monthlyChart ? '#FFB300' : "#FFFF"
			},
		]
	};

	const collectedPaymentsDates = state.collectedData.map((date) => {
		return (tabIndex ? date.displayName : formatDate(date.displayName))
	})

	const collectedAmounts = state.collectedData.map((amount) => {
		return (amount.amount || 0)
	})
	const collectedPaymentsData = {
		labels: collectedPaymentsDates,
		datasets: [
			{
				label: t("dashboard.collected_payments"),
				data: collectedAmounts,
				fill: false,
				borderColor: '#FFB300',
				backgroundColor: state.monthlyChart ? '#FFB300' : "#FFFF"
			},
		]
	};

	const missedPaymentsDates = state.missedData.map((date) => {
		return (tabIndex ? date.displayName : formatDate(date.displayName))
	})

	const missedAmounts = state.missedData.map((amount) => {
		return (amount.amount || 0)
	})
	const missedPaymentsData = {
		labels: missedPaymentsDates,
		datasets: [
			{
				label: t("dashboard.missed_payments"),
				data: missedAmounts,
				fill: false,
				borderColor: '#FFB300',
				backgroundColor: state.monthlyChart ? '#FFB300' : "#FFFF"
			},
		]
	};

	function formatMonthYear(dateString) {
		const dateParts = dateString.split('-');
		const year = dateParts[0];
		const month = dateParts[1];

		// Convert month number to month name
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const monthName = monthNames[parseInt(month) - 1];

		return `${monthName}-${year}`;
	}

	const pendingPaymentsDates = state.pendingAmountData.map((date) => {
		return (tabIndex ? formatMonthYear(date.scheduledDate)
			: formatDate(date.scheduledDate))
	})

	const pendingAmounts = state.pendingAmountData.map((amount) => {
		return (amount.amountDue || 0)
	})
	const pendingPaymentsData = {
		labels: pendingPaymentsDates,
		datasets: [
			{
				label: t("dashboard.pending_payments"),
				data: pendingAmounts,
				fill: false,
				borderColor: '#FFB300',
				backgroundColor: state.monthlyChart ? '#FFB300' : "#FFFF"
			},
		]
	};

	const dateSubscribed = state.activeData.map((date) => {
		return (tabIndex ? date.displayName : formatDate(date.displayName))
	})

	const activeIncidenceCount = state.activeData.map((subscribers) => {
		return (subscribers.incidence)
	})
	const activeSubscriptionsData = {
		labels: dateSubscribed,
		datasets: [
			{
				label: t("dashboard.active_subscriptions"),
				data: activeIncidenceCount,
				fill: false,
				borderColor: '#FFB300',
				backgroundColor: state.monthlyChart ? '#FFB300' : "#FFFF"
			},
		]
	};

	const dateCancelled = state.cancelledData.map((date) => {
		return (tabIndex ? date.displayName : formatDate(date.displayName))
	})

	const cancelledIncidenceCount = state.cancelledData.map((subscribers) => {
		return (subscribers.incidence)
	})

	const cancelledSubscriptionsData = {
		labels: dateCancelled,
		datasets: [
			{
				label: t("dashboard.cancelled_subscriptions"),
				data: cancelledIncidenceCount,
				fill: false,
				borderColor: '#FFB300',
				backgroundColor: state.monthlyChart ? '#FFB300' : "#FFFF"
			},
		]
	};

	const totalCancelledSubscriptions = cancelledIncidenceCount.reduce((a, b) => a + b, 0);
	const totalActiveSubscriptions = activeIncidenceCount.reduce((a, b) => a + b, 0);
	const totalNewSubscriptions = newIncidenceCount?.reduce((a, b) => a + b, 0)

	//Toggle merchant onboarding onhide
	const toggleSetOpenDialog = () => {
		setOpenNotification(!openNotification); setConfigureUrl("");
		localStorage.setItem("onboardingDialog", false);
	};

	return (
		<>
			<div className="layout-dashboard">
				<OnboardingNotification visible={openNotification}
					onHide={toggleSetOpenDialog}
				/>
				<ProductCreate toggleDialog={toggleTierDialog} plan={false} duplicate={state.duplicate} toggleState={state.tierVisible} tierGroup={null} />
				<div className="p-grid">
					<div className="p-col-12 p-col-6" style={{ display: 'flex', flexDirection: "column", width: "100%" }}>
						<SummaryCard defMembership={defMembership} businessSummary={state.businessSummaryObject} planGroupPerformance={state.planGroupPerformanceObject} />
						<TabMenu
							model={state.tabMenuItems.map((item) => {
								item.label = t(item.label);
								return item;
							})}
							className={"p-tabMenu"}
							style={{ marginBottom: "30px" }}
							activeIndex={activeIndex}
							onTabChange={(e) => {
								if (e.index === 1) {
									setActiveIndex(e.index)
									getLast30DaysDaily();
								}
								if (e.index === 2) {
									setActiveIndex(e.index)
									getWeekly();
									getLast12WeeksWeekly();
								}
								if (e.index === 3) {
									setActiveIndex(e.index)
									get3Months();
									getLast3MonthsMonthly();
								}
								if (e.index === 4) {
									setActiveIndex(e.index)
									get6Months();
									getLast6MonthsMonthly()
								}
								if (e.index === 5) {
									setActiveIndex(e.index)
									get12Months();
									getLast12MonthsMonthly()
								}
								setState((prev) => {
									return { ...prev, activeTab: e.index };
								});
							}}
						/>
					</div>
					<div>
						<DashboardCharts newSubscriptionsData={newSubscriptionsData} collectedPaymentsData={collectedPaymentsData} missedPaymentsData={missedPaymentsData} pendingPaymentsData={pendingPaymentsData}
							defMembership={defMembership} setState={setState} activeSubscriptionsData={activeSubscriptionsData} cancelledSubscriptionsData={cancelledSubscriptionsData}
							totalCancelledSubscriptions={totalCancelledSubscriptions} totalNewSubscriptions={totalNewSubscriptions} totalActiveSubscriptions={totalActiveSubscriptions}
							paymentScheduleSummaryObject={summaryData} duration={state.duration}
							monthlyChart={state.monthlyChart} activeIndex={activeIndex} loadingChart={loading} actualPendingAmount={totalPending} idex={activeIndex}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
